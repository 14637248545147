import React, {useState, useEffect, useCallback, useRef} from "react";
import {Modal, styled, Box, Typography, modalClasses, Stack, Button, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, Select, OutlinedInput, MenuItem, TextField, InputAdornment, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import BeatLoader from "react-spinners/BeatLoader";
import AddIcon from '@mui/icons-material/Add';


const StyledModal = styled(Modal)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    //paddingTop:"150px",
    //paddingBottom:"60px",
    overflow:"auto"
});
const ITEM_HEIGHT = 48;
const MenuProps = {
    PaperProps: {
   style: {
     maxHeight: ITEM_HEIGHT * 8.5,
     padding:'10px'  
    },
    },
  };
const AddRetailer = ({addRetOpen, addRetClose, retailers, handleChangedd, searched, retailerd, handleChangerd, catchId, addressChange, retailer_address, handleNex, initial, addNewRet, error}) => {
  return (
    <StyledModal
    open={addRetOpen}
    //onClose={checkOutClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description">
        <Box sx={{width:{xs: "93vw", sm: "93vw", md:"40vw"},px:{xs:'20px', sm:'20px', md: '44px'}, py:'30px', backgroundColor:'white', borderRadius:'10px', display:'block'}}>
            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                <Typography variant='h5' sx={{fontWeight:'bold'}}>Add Retailer</Typography>
                <CloseIcon sx={{cursor:'pointer'}} onClick={()=>addRetClose()}/>
            </Box>
            <Box sx={{marginTop:'16px', display:'block', width:'100%', p:'0px'}}>
                <Box sx={{display:'block', p:'0px', m:'0px'}}>
                    <Typography sx={{color:'rgba(0,0,0, 0.5)', fontWeight:'bold'}}>Select Retailer</Typography>
                    <Select
                    sx={{width:'100%', border:'1px solid #A1A1A1', borderRadius:'8px'}}
                    labelId="demo-simple-select-label"
                    label="Select Retailer"
                    id="demo-simple-select"
                    value={retailerd}
                    onChange={(event)=>handleChangerd(event)}
                    input={<OutlinedInput label="Name" />}
                    MenuProps={MenuProps}>
                        <Box sx={{paddingBottom: '16px', paddingTop:'0px', paddingRight:'0px', paddingLeft:'0px', width:'100%'}}>
                        <OutlinedInput  sx={{backgroundColor: 'white', height: '50px', borderRadius: '10px', width:'100%'}}
                        id="outlined-adornment-password"
                        type="number"
                        placeholder="Search..."
                        onChange={(e)=>handleChangedd(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              //onClick={handleClickShowPassword}
                              //onMouseDown={handleMouseDownPassword}
                              edge="end" 
                            >
                              <SearchIcon sx={{color:'#28A745'}} />
                            </IconButton>
                          </InputAdornment>
                        }/>
                        </Box>
                        {retailers.filter((retailer)=>{
                            return searched === '' ? retailer: retailer.phone.includes(searched)
                        }).map((retailer)=>(
                            <MenuItem value={retailer.phone} key={retailer.id} onClick={()=>catchId(retailer.id)}>{retailer.phone}</MenuItem>
                        ))}
                        <Button
                        variant="Conatined"
                        onClick={()=>addNewRet()}
                        sx={{color:'white',backgroundColor: '#28A745',display:'block', position:'absolute', borderRadius:'50%', zIndex:'2', bottom:'0px', left:'40%', padding:'8px', boxShadow:'1px 3px 4px rgba(0,0,0,0.3)'}}>
                            <AddIcon />
                            <Typography>Add new</Typography>
                        </Button>
                    </Select>
                </Box>
                <Box sx={{display:'block', mt:'16px', p:'0'}}>
                    <Typography sx={{color:'rgba(0,0,0, 0.5)', fontWeight:'bold'}}>Retailer Address</Typography>
                    <TextField 
                    sx={{border:'1px solid #A1A1A1', borderRadius:'8px', width:'100%'}}
                    id="outlined-multiline-static"
                    multiline
                    type="text"
                    value={retailer_address}
                    rows={2}
                    placeholder="Enter Retailer address here"
                    onChange={(e)=>addressChange(e)}
                    />
                    {error && <Typography variant="p" sx={{color:'red', marginTop:'8px'}}>Please enter a valid address</Typography>}
                </Box>
                {initial === true ? <Button 
                variant="Conatined"
                onClick={()=>handleNex()}
                sx={{color:'white', height: '50px', width:'100%',backgroundColor: '#28A745',marginTop:'16px', borderRadius: '10px', '&:hover': {backgroundColor:'#44ab5c'}}}>
                    Next
                </Button> : <Box sx={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center',p:'0px', mt:'24px'}}>
                    <BeatLoader color="#28A745" size={20}/>
                </Box>}
            </Box>
        </Box>
    </StyledModal>
  )
}

export default AddRetailer
