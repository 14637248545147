import { useRoutes } from "react-router-dom";
import React from "react";
import CurrentUser from "./Components/CurrentUser";
import Dashboard from "./Components/Dashboard";
import Orders from "./Components/Orders";
import Products from "./Components/Products";
import Reports from "./Components/Reports";
import Settings from "./Components/Settings";
import AddProducts from "./Components/Sub-Components/AddProducts";
import Login from "./Components/Sub-Components/Login";
import ProfileInformation from "./Components/Sub-Components/ProfileInformation";
import PasswordReset from "./Components/Sub-Components/PasswordReset";
import AddOrders from "./Components/Sub-Components/AddOrders";
import Preferences from "./Components/Sub-Components/Preferences";
import Logistics from "./Components/Logistics";

const Router = () => {
  const userToken = localStorage.getItem("token");

  return useRoutes([
    { path: "/login", element: <Login /> },
    {
      path: "/",
      default: true,
      element: userToken === null ? <Login /> : <Dashboard />,
    },
    { path: "/Orders", element: userToken === null ? <Login /> : <Orders /> },
    {
      path: "/Products",
      element: userToken === null ? <Login /> : <Products />,
    },
    { path: "/Reports", element: userToken === null ? <Login /> : <Reports /> },
    {
      path: "/Settings",
      element: userToken === null ? <Login /> : <Settings />,
    },
    {
      path: "/CurrentUser",
      element: userToken === null ? <Login /> : <CurrentUser />,
    },
    {
      path: "/Logistics",
      element: userToken === null ? <Login /> : <Logistics />,
    },
    {
      path: "/AddProducts",
      element: userToken === null ? <Login /> : <AddProducts />,
    },
    {
      path: "/profileinformation",
      element: userToken === null ? <Login /> : <ProfileInformation />,
    },
    {
      path: "/passwordreset",
      element: userToken === null ? <Login /> : <PasswordReset />,
    },
    {
      path: "/addorder",
      element: userToken === null ? <Login /> : <AddOrders />,
    },
    {
      path: "/preference",
      element: userToken === null ? <Login /> : <Preferences />,
    },
  ]);
};

export default Router;
