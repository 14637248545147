import React, {useState, useEffect, useCallback, useRef} from "react";
import {Modal, styled, Box, Typography, modalClasses, Stack, Button, TableContainer, TableHead, TableRow, TableCell, Table, TableBody} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const StyledModal = styled(Modal)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    paddingTop:"60px",
    paddingBottom:"60px",
    overflow:"auto"
});

const CheckOutModal = ({checkOutOpen, checkOutClose, prod, totalCosts, handleNext}) => {
  return (
    <StyledModal 
    open={checkOutOpen}
    //onClose={checkOutClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description">
        <Box sx={{width:{xs: "93vw", sm: "93vw", md:"50vw"},px: {xs:'20px', sm:'20px', md: '44px'}, py:'30px',maxHeight:'90vh',overflow:'auto', backgroundColor:'white', borderRadius:'10px', display:'block'}}>
            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                <Typography variant='h5' sx={{fontWeight:'bold'}}>Check Out</Typography>
                <CloseIcon sx={{cursor:'pointer'}} onClick={()=>checkOutClose()}/>
            </Box>
            <Box sx={{marginTop:'16px', display:'block'}}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow sx={{backgroundColor: '#28A745'}}>
                                <TableCell sx={{backgroundColor:'transparent' , color:'#fff'}}>Product Name</TableCell>
                                <TableCell sx={{backgroundColor:'transparent' , color:'#fff'}}>Quantity</TableCell>
                                <TableCell sx={{backgroundColor:'transparent' , color:'#fff'}}>Unit cost</TableCell>
                                <TableCell sx={{backgroundColor:'transparent' , color:'#fff'}}>Cost</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{backgroundColor:'#C7E7CE'}}>
                            {prod.map((pro)=>(
                                <TableRow key={pro._id}>
                                    <TableCell>{pro.product_name}</TableCell>
                                    <TableCell>{pro.prices[0].quantity}</TableCell>
                                    <TableCell>{pro.prices[0].amount}</TableCell>
                                    <TableCell>{pro.prices[0].quantity*pro.prices[0].amount}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginTop:'8px'}}>
                    <Typography variant="p" sx={{fontWeight:'600'}}>Total Cost</Typography>
                    <Typography variant="p" sx={{fontWeight:'600', color:'#28A745'}}>{totalCosts}</Typography>
                </Box>
                <Box sx={{marginTop:'16px', display:'flex', flexDirection: {xs:'column', sm:'column', md:'row'}, justifyContent:'center', alignItems:'center'}}>
                    <Button 
                    variant="outlined"
                    onClick={()=>checkOutClose()}
                    sx={{height: '50px',width:{xs:'45vw', sm:'45vw'}, border:'1px solid #28A745',color:'#28A745', borderRadius: '10px', '&:hover': {backgroundColor:'#28A745', color:'white', border:'1px solid transparent'}}}>
                        Add More Items
                    </Button>
                    <Button 
                    variant="Conatined"
                    onClick={()=>handleNext()}
                    sx={{marginLeft: {xs:'0px', sm:'0px', md:'8px'},width:{xs:'45vw', sm:'45vw'}, marginTop: {xs:'8px', sm:'8px', md:'0px'}, color:'white', height: '50px', paddingLeft:'32px', paddingRight:'32px',backgroundColor: '#28A745', borderRadius: '10px', '&:hover': {backgroundColor:'#44ab5c'}}}>
                        Next
                    </Button>
                </Box>
            </Box>
        </Box>
    </StyledModal>
  )
}

export default CheckOutModal
