import React, { useState } from "react";
import {
  Modal,
  styled,
  Box,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import SearchShopper from "./SearchShopper";
import BeatLoader from "react-spinners/BeatLoader";
import "react-toastify/dist/ReactToastify.css";

const StyledModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "60px",
  paddingBottom: "60px",
  overflow: "auto",
});

const AssignShoppertoRet = ({
  assignModal,
  assignClose,
  newShoppers,
  handleChaned,
  value,
  handleCheck,
  initial,
  unassignOrder,
  shopper,
}) => {
  const [searcher, setSearcher] = useState("");
  const token = localStorage.getItem("token");

  let shopper_id = shopper.id;

  let bodyData = {
    shopper_id: shopper.id,
    shopper_address: "",
    latitude: shopper.shopper?.last_latitude,
    longitude: shopper.shopper?.last_longitude,
  };

  const handleChangedd = (value) => {
    setSearcher(value);
  };

  const url = `https://api.allroundcare.org/admin/assign_order_to_shopper?id=${unassignOrder._id}`;

  const handlesub = () => {
    const options = {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer" + " " + token,
      },
      body: JSON.stringify(bodyData),
    };

    if (shopper_id === undefined) {
      alert("Please select a shopper");
    } else {
      fetch(url, options)
        .then((res) => {
          if (res.status !== 200) {
            console.log(res);
            throw Error("Unable to fetch");
          }
          return res.json();
        })
        .then((res) => {
          console.log(res);
          if (res.msg === "success") {
            alert(
              `Order assigned to ${shopper.first_name} ${shopper.last_name} is successful`
            );
          }
          assignClose();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <StyledModal
      open={assignModal}
      onClose={assignClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          width: { xs: "90vw", sm: "90vw", md: "40vw" },
          maxHeight: "90vh",
          overflow: "auto",
          px: { xs: "20px", sm: "20px", md: "44px" },
          py: { xs: "20px", sm: "20px", md: "30px" },
          backgroundColor: "white",
          borderRadius: "10px",
          display: "block",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Assign Shopper</Typography>
          <CloseIcon sx={{ cursor: "pointer" }} onClick={() => assignClose()} />
        </Box>
        <SearchShopper handleChangedd={handleChangedd} />
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChaned}
          >
            {newShoppers
              .filter((shopper) => {
                return searcher.toLowerCase() === ""
                  ? shopper
                  : shopper.first_name.toLowerCase().includes(searcher);
              })
              .map((shopper) => (
                <FormControlLabel
                  onClick={() => handleCheck(shopper.id, shopper)}
                  value={shopper.first_name + " " + shopper.last_name}
                  control={
                    <Radio
                      sx={{
                        color: "#28A745",
                        "&.Mui-checked": {
                          color: "#28A745",
                        },
                      }}
                    />
                  }
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: { xs: "70vw", sm: "60vw", md: "30vw" },
                      }}
                    >
                      <Typography>
                        {shopper.first_name + " " + shopper.last_name}
                      </Typography>
                      <Typography>{shopper.distance}Km</Typography>
                    </Box>
                  }
                  key={shopper.id}
                />
              ))}
          </RadioGroup>
        </FormControl>
        {initial === true ? (
          <Button
            variant="contained"
            type="button"
            onClick={handlesub}
            sx={{
              width: "100%",
              height: "44px",
              backgroundColor: "#28A745",
              color: "white",
              mt: "24px",
              borderRadius: "8px",
              alignItems: "center",
              "&:hover": { backgroundColor: "#44ab5c" },
            }}
          >
            Proceed
          </Button>
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: "0px",
              mt: "24px",
            }}
          >
            <BeatLoader color="#28A745" size={20} />
          </Box>
        )}
      </Box>
    </StyledModal>
  );
};

export default AssignShoppertoRet;
