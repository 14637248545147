import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
  Box,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ReasonModal from "./ReasonModal";
import AssignShoppertoRet from "./AssignShoppertoRet";

import { getDistance } from "geolib";
import { useQuery } from "react-query";

const ITEM_HEIGHT = 48;

const ShopperDecline = ({ declinedOrder, shoppers, retailers }) => {
  const [decl, setDecl] = useState({});
  const [decTrue, setDecTrue] = useState(false);
  const [decOpen, setDecOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [isAssignTrue, setIsAssignTrue] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  // const [onlineShoppers, setOnlineShoppers] = useState([]);
  const [value, setValue] = useState("");
  const [shopper_id, setShopper_id] = useState();
  const [shopper_location, setShopper_location] = useState({});
  const [shopper, setShopper] = useState({});
  const [initial, setInitial] = useState(true);
  const [unassignOrder, setUnassignOrder] = useState({});
  const [newShoppers, setNewShoppers] = useState([]);

  const userToken = localStorage.getItem("token");

  const allOnlineShoppers = async () => {
    return await fetch(
      `https://api.allroundcare.org/admin/all_online_shoppers`,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    ).then((res) => {
      if (res.status !== 200) {
        throw Error("Unable to fetch data from resource");
      }
      return res.json();
    });
  };

  const handleCheck = (id, shopper) => {
    let shop = {
      latitude: shopper.shopper.last_latitude,
      longitude: shopper.shopper.last_longitude,
    };
    setShopper_location(shop);
    setShopper_id(id);
    setShopper(shopper);
  };

  const assignClose = () => {
    setIsAssignTrue(false);
    setAssignModal(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClick = (events, id, declined) => {
    setAnchorEl(events.currentTarget);
    // setDecl(declined);
    console.log(id);
    let newUnassigned = {};
    newUnassigned.retailer_id = declined.retailer_id;
    newUnassigned.retailer_address = declined.retailer_address;
    newUnassigned.retailer_location = declined.retailer_location;
    newUnassigned.products = declined.products;
    newUnassigned.createdAt = declined.createdAt;
    newUnassigned._id = declined._id;
    retailers.map((ret) => {
      if (ret.id === declined.retailer_id) {
        newUnassigned.name = ret.first_name + " " + ret.last_name;
        newUnassigned.phone = ret.phone;
      }
    });
    setUnassignOrder(newUnassigned);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleReason = () => {
    console.log(decl);
    setDecTrue(true);
    setDecOpen(true);
  };
  const decClose = () => {
    setDecOpen(false);
    setDecTrue(false);
  };

  const handleChaned = (event) => {
    setValue(event.target.value);
  };

  const { data: onlineShoppers, isLoading } = useQuery(
    "all-online-shoppers",
    allOnlineShoppers,
    {
      refetchInterval: 3000,
    }
  );

  if (isLoading) {
    return (
      <Box>
        <Typography variant="h5">Loading...</Typography>
      </Box>
    );
  }

  const handleAssign = () => {
    setIsAssignTrue(true);
    setAssignModal(true);

    let newData = [];

    if (onlineShoppers.length > 0) {
      newData = onlineShoppers.map((allElements) => {
        return {
          ...allElements,
          distance:
            getDistance(
              {
                latitude: allElements.shopper.last_latitude,
                longitude: allElements.shopper.last_longitude,
              },
              {
                latitude: unassignOrder.retailer_location.latitude,
                longitude: unassignOrder.retailer_location.longitude,
              }
            ) / 1000,
        };
      });
    }
    setNewShoppers(newData);
  };

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#28A745" }}>
              <TableCell sx={{ backgroundColor: "transparent", color: "#fff" }}>
                Shopper Name
              </TableCell>
              <TableCell sx={{ backgroundColor: "transparent", color: "#fff" }}>
                Order Date
              </TableCell>
              <TableCell sx={{ backgroundColor: "transparent", color: "#fff" }}>
                Decline Date
              </TableCell>
              <TableCell sx={{ backgroundColor: "transparent", color: "#fff" }}>
                Status
              </TableCell>
              <TableCell sx={{ backgroundColor: "transparent", color: "#fff" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "#C7E7CE" }}>
            {declinedOrder
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((declined) => (
                <TableRow key={declined._id}>
                  <TableCell>
                    {shoppers.map((shopper) => (
                      <Typography>
                        {shopper.id === declined.shopper_id
                          ? shopper.first_name + " " + shopper.last_name
                          : null}
                      </Typography>
                    ))}
                  </TableCell>
                  <TableCell>{declined.date}</TableCell>
                  <TableCell>
                    {declined.status.map((dec) => (
                      <Typography>
                        {dec.name === "declined by shopper"
                          ? dec.addedDate.slice(0, 10)
                          : null}
                      </Typography>
                    ))}
                  </TableCell>
                  <TableCell>
                    {declined.status.map((dec) => (
                      <Typography>
                        {dec.name === "declined by shopper" ? dec.name : null}
                      </Typography>
                    ))}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={(events) =>
                        handleClick(events, declined._id, declined)
                      }
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: "20ch",
                          boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
                        },
                      }}
                    >
                      <MenuItem onClick={() => handleReason()}>
                        View Reason
                      </MenuItem>
                      <MenuItem onClick={() => handleAssign()}>
                        Assign Shopper
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {decTrue && (
        <ReasonModal decOpen={decOpen} decClose={decClose} decl={decl} />
      )}
      {isAssignTrue && (
        <AssignShoppertoRet
          assignModal={assignModal}
          assignClose={assignClose}
          newShoppers={onlineShoppers}
          value={value}
          handleChaned={handleChaned}
          handleCheck={handleCheck}
          initial={initial}
          unassignOrder={unassignOrder}
          shopper={shopper}
        />
      )}
      <TablePagination
        rowsPerPageOptions={[10, 20, 30, 40]}
        component="div"
        count={declinedOrder.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default ShopperDecline;
