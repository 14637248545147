import React, { useState, useEffect } from "react";
import { GiNetworkBars } from "react-icons/gi";
import { HiShoppingBag } from "react-icons/hi";
import { ImDropbox, ImCart } from "react-icons/im";
import { BsFillBasket2Fill } from "react-icons/bs";
import { RiEBikeFill } from "react-icons/ri";
import Card from "./card/Card";
import "../dashboard/dashboard.css";
import Chart from "./Chart/Chart";
import OrderedProduct from "./ordered_product/OrderedProduct";
import axios from "axios";

const DashbordMain = () => {
  const token = localStorage.getItem("token");
  const [packages, setPackages] = useState([]);
  const [dispatch, setDispatch] = useState([]);
  const [product, setProduct] = useState([]);
  const [shoppers, setShoppers] = useState([]);
  const [retailers, setRetailers] = useState([]);
  const [retailOrder, setRetailOrder] = useState([]);

  //get retailers Order
  useEffect(() => {
    const allRetailOrders = async () => {
      try {
        const response = await axios.get(
          "https://api.allroundcare.org/admin/all_retailer_order",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status !== 200) {
          throw new Error("Unable to fetch data from resources");
        } else if (response.status === 200) {
          setRetailOrder(response.data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    allRetailOrders();
  }, []);
  // get total retailers
  useEffect(() => {
    const allRetailers = async () => {
      try {
        const response = await axios.get(
          "https://api.allroundcare.org/users/retialers",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status !== 200) {
          throw new Error("Unable to fetch data from resources");
        } else if (response.status === 200) {
          setRetailers(response.data.body);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    allRetailers();
  }, []);
  // get all shopper
  useEffect(() => {
    const allShoppers = async () => {
      try {
        const response = await axios.get(
          "https://api.allroundcare.org/users/shoppers",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status !== 200) {
          throw new Error("Unable to fetch data from resources");
        } else if (response.status === 200) {
          setShoppers(response.data.body);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    allShoppers();
  }, []);
  //get all product
  useEffect(() => {
    const allProducts = async () => {
      try {
        const response = await axios.get(
          "https://api.allroundcare.org/utilites/unlimited_products",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status !== 200) {
          throw new Error("Unable to fetch data from resources");
        } else if (response.status === 200) {
          setProduct(response.data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    allProducts();
  }, []);
  // get all dispatch rider
  useEffect(() => {
    const allLogistics = async () => {
      try {
        const response = await axios.get(
          "https://api.allroundcare.org/admin/all_dispatch_drivers",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status !== 200) {
          throw new Error("Unable to fetch data from resources");
        } else if (response.status === 200) {
          setDispatch(response.data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    allLogistics();
  }, []);
  // get all package for the logistic app
  useEffect(() => {
    const getAllPackages = async () => {
      try {
        const response = await axios.get(
          "https://api.allroundcare.org/admin/packages/all-packages",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status !== 200) {
          throw new Error("Unable to fetch data from resources");
        } else if (response.status === 200) {
          setPackages(response.data.data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    getAllPackages();
  }, []);

  return (
    <>
      <section className="dashboard">
        <div className="card_section">
          <Card
            title={"Total Revenue"}
            amount={"N120, 000"}
            icon={<GiNetworkBars />}
          />
          <Card
            title={"Total Order"}
            amount={retailers.length}
            icon={<HiShoppingBag />}
          />
          <Card
            title={"Total Product"}
            amount={product.length}
            icon={<ImDropbox />}
          />
          <Card
            title={"Total Retailers"}
            amount={retailOrder.length}
            icon={<BsFillBasket2Fill />}
          />
          <Card
            title={"Total Shoppers"}
            amount={shoppers.length}
            icon={<ImCart />}
          />
          <Card
            title={"Total Dispatch Riders"}
            amount={dispatch.length}
            icon={<RiEBikeFill />}
          />
          <Card
            title={"Total Package Logistic"}
            amount={packages.length}
            icon={<ImDropbox />}
          />
        </div>

        {/* <div className="section_three">
          <Chart />
          <OrderedProduct />
        </div> */}
      </section>
    </>
  );
};

export default DashbordMain;
