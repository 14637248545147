import React from 'react';
import Navbar from './Navbar';
import { Box, Stack } from "@mui/material";
import Sidebar5 from './SideBar5';
import ProfileMain from './ProfileMain';
import PasswordResetMain from './PasswordResetMain';

const PasswordReset = () => {
  return (
    <Box>
        <Navbar />
        <Box sx={{ display: "flex", flexDirection: 'row', padding: '0px', marginTop: '80px', justifyContent: 'center'}}>
            <Sidebar5 />
            <PasswordResetMain />
        </Box>
    </Box>
  )
}

export default PasswordReset
