import { Box, OutlinedInput, IconButton,InputAdornment,  } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, {useState, useEffect, useCallback, useRef} from "react"


const SearchBar = ({search, handleChange}) => {
    return ( 
        <Box component='div' sx={{position: 'relative', display: 'flex',padding: '0px'}}>
            <OutlinedInput  sx={{backgroundColor: 'white', height: '50px', borderRadius: '10px', width: {xs:'100%', sm:'100%'}}}
            id="outlined-adornment-password"
            type="text"
            placeholder="Search..."
            onChange={(e)=>handleChange(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  //onClick={handleClickShowPassword}
                  //onMouseDown={handleMouseDownPassword}
                  edge="end" 
                >
                  <SearchIcon sx={{color:'#28A745'}} />
                </IconButton>
              </InputAdornment>
            }
          />
        </Box>
     );
}
 
export default SearchBar;