import React from 'react';
import Navbar from './Sub-Components/Navbar';
import { Box, Stack } from '@mui/material';
import SettingsMain from './Sub-Components/SettingsMain';
import Sidebar5 from './Sub-Components/SideBar5';

const Settings = () => {
  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '0px',
          marginTop: '80px',
          justifyContent: 'center',
        }}
      >
        <Sidebar5 />
        <SettingsMain />
      </Box>
    </Box>
  );
};

export default Settings;
