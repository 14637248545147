import React, {useState, useEffect, useCallback, useRef} from "react";
import {Modal, styled, Box, Typography, modalClasses, Stack, Button, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {ReactComponent as Mail} from '../../Svg/Mail.svg';
import TextField from '@mui/material/TextField';


const StyledModal = styled(Modal)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    //paddingTop:"150px",
    //paddingBottom:"60px",
    overflow:"auto"
});

const SetPassword = ({newPasswordOpen, handlePassClose, msg, email, token, handleCode, handleNewPassword, password, submitNewPassword}) => {
  return (
    <StyledModal
    open={newPasswordOpen}
    onClose={handlePassClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description">
        <Box sx={{width:{xs: "90vw", sm: "90vw", md:"40vw"},px:'44px', py:'30px', backgroundColor:'white', borderRadius:'10px', display:'block'}}>
            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                <CloseIcon sx={{cursor:'pointer'}} onClick={()=>handlePassClose()}/>
            </Box>
            <Box sx={{width:'100%',padding:'0px', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                <Mail style={{width:'200px', height:'150px'}}/>
                <Typography variant="h6">Set Password</Typography>
                <Typography variant="p" sx={{color:'rgba(0,0,0, 0.3)', textAlign:'center'}}>A verification code has been sent to <span style={{fontWeight:'500', color:'rgba(0,0,0, 0.5)'}}>{email}</span>. Please provide the code and a new password for your account</Typography>
            </Box>
            <Box component="form" onSubmit={(e)=>submitNewPassword(e)} sx={{display:'flex',flexDirection:'column', alignItems:'center', width:'100%', padding:'0px',marginTop:'16px'}}>
                <Box sx={{display:'flex', flexDirection:'column', width:'100%',padding:'0px', m:'0px'}}>
                    <Typography variant="p" sx={{fontWeight:'500', color:'rgba(0,0,0,0.8)'}}>verification Code</Typography>
                    <TextField 
                    required
                    type="text"
                    id="outlined-required"
                    value={token}
                    placeholder='Enter verification code here'
                    onChange={(e)=>handleCode(e)}
                    sx={{width:'100%', borderRadius:'8px',mt:'5px'}}
                    />
                </Box>
                <Box sx={{display:'flex', flexDirection:'column', width:'100%',padding:'0px', mt:'16px'}}>
                <Typography variant="p" sx={{fontWeight:'500', color:'rgba(0,0,0,0.8)'}}>Password</Typography>
                    <TextField 
                    required
                    type="password"
                    id="outlined-required"
                    value={password}
                    placeholder='Enter a new password here'
                    onChange={(e)=>handleNewPassword(e)}
                    sx={{width:'100%', borderRadius:'8px',mt:'5px'}}
                    />
                </Box>
                <Button 
                variant="contained"
                type="submit"
                sx={{width:'100%',height:'48px', backgroundColor:'#28A745', color:'white', mt:'24px', borderRadius:'8px', alignItems:'center', '&:hover': {backgroundColor:'#44ab5c'}}}>
                    Set Password
                </Button>
            </Box>
        </Box>
    </StyledModal>
  )
}

export default SetPassword
