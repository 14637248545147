import React, { useState } from "react";
import { Box, Typography, Button, TextField } from "@mui/material";
import Logo from "../../Images/Arclogo.svg";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ForgotPasswordModal from "./ForgotPasswordModal";
import SetPassword from "./SetPassword";
import { toast } from "react-toastify";
import axios from "axios";

const schema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  password: yup.string().required("Password is required"),
});

const Login = () => {
  const [errorCheck, setErrorCheck] = useState(false);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [forgotCheck, setForgotCheck] = useState(false);
  const [email, setEmail] = useState("");
  const [passCheck, setPassCheck] = useState(false);
  const [newPasswordOpen, setNewPasswordOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const handleForget = () => {
    setForgotCheck(true);
    setForgotPasswordOpen(true);
  };
  const closeForget = () => {
    setForgotCheck(false);
    setForgotPasswordOpen(false);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePassClose = () => {
    setPassCheck(false);
    setNewPasswordOpen(false);
  };
  const handleCode = (e) => {
    setToken(e.target.value);
  };
  const handleNewPassword = (e) => {
    setPassword(e.target.value);
  };

  const submitEmail = (e) => {
    try {
      e.preventDefault();
      if (email.length === 0) {
        toast.error("Please input your email", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        fetch(
          `https://api.allroundcare.org/accounts/forgot_password?email=${email}`,
          {
            method: "PUT",
          }
        ).then((res) => {
          if (res.status === 200) {
            setMsg(res.msg);
            setForgotPasswordOpen(false);
            setForgotCheck(false);
            setPassCheck(true);
            setNewPasswordOpen(true);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitNewPassword = async (e) => {
    try {
      e.preventDefault();
      let data = { email, token, password };
      if (password.length === 0 && token.length === 0) {
        console.log("cannot be empty");
      } else if (password.length !== 0 && token.length === 0) {
        console.log("token cannot be empty");
      } else if (password.length === 0 && token.length !== 0) {
        console.log("password cannot be empty");
      } else {
        console.log(data);

        const response = await fetch(
          "https://api.allroundcare.org/accounts/set_password",
          {
            method: "PUT",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        if (response.status !== 200) {
          console.log("not sent");
        } else {
          setPassCheck(false);
          setNewPasswordOpen(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const submit = (data) => {
    axios
      .post("https://api.allroundcare.org/accounts/login", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.status !== 200) {
          toast.error(`You're not authorized`, {
            position: "top-right",
            autoClose: 500,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
        return response.data;
      })
      .then((body) => {
        console.log(body);
        if (
          body.msg === "email is incorrect" ||
          body.msg === "password is incorrect"
        ) {
          toast.error(body.msg, {
            position: "top-right",
            autoClose: 500,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else if (body.type === "success" && body.body.type === "admin") {
          localStorage.setItem("token", body.body.token);
          localStorage.setItem("type", body.body.type);
          navigate("/");

          toast.success(body.msg, {
            position: "top-right",
            autoClose: 500,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.message === "Network Error") {
          toast.error("Poor internet connection", {
            position: "top-right",
            autoClose: 500,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img
        src={Logo}
        placeholder="logo"
        style={{ width: "100px", height: "100px" }}
        alt="ARC logo"
      />
      <Typography variant="h5" sx={{ marginTop: "8px" }}>
        Welcome back!
      </Typography>
      <Box
        sx={{
          width: { xs: "90vw", sm: "60vw", md: "30vw" },
          border: "1px solid rgba(0,0,0, 0.2)",
          borderRadius: "8px",
          padding: "20px",
          marginTop: "24px",
        }}
      >
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onSubmit={handleSubmit(submit)}
        >
          {/* <Box sx={{ width: "100%", marginBottom: "16px", padding: "0px" }}>
            {errorCheck && (
              <Typography variant="p" sx={{ color: "red" }}>
                {error}
              </Typography>
            )}
          </Box> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontWeight: "500", color: "rgba(0,0,0,0.8)" }}
            >
              Email
            </Typography>
            <TextField
              required
              type="email"
              id="outlined-required"
              placeholder="Enter your Email"
              {...register("email")}
              sx={{ width: "100%", borderRadius: "8px", height: "44px" }}
            />
            <Typography
              variant="p"
              sx={{ color: "red", fontSize: "14px", marginTop: "8px" }}
            >
              {errors.email?.message}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              marginTop: "24px",
              width: "100%",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontWeight: "500", color: "rgba(0,0,0,0.8)" }}
            >
              Password
            </Typography>
            <TextField
              required
              type="password"
              id="outlined-required"
              placeholder="Enter your password"
              {...register("password")}
              sx={{ width: "100%", borderRadius: "8px", height: "44px" }}
            />
            <Typography
              variant="p"
              sx={{ color: "red", fontSize: "14px", marginTop: "8px" }}
            >
              {errors.password?.message}
            </Typography>
          </Box>
          <Button
            type="submit"
            sx={{
              width: "100%",
              color: "white",
              marginTop: "24px",
              borderRadius: "10px",
              height: "48px",
              backgroundColor: "#28A745",
              "&:hover": { backgroundColor: "#44ab5c" },
            }}
          >
            Log In
          </Button>
        </form>
        <Box sx={{ width: "100%", marginTop: "16px", padding: "0px" }}>
          <Typography
            onClick={() => handleForget()}
            variant="p"
            sx={{ fontWeight: "500", cursor: "pointer" }}
          >
            Forgot Password
          </Typography>
        </Box>
      </Box>
      {forgotCheck && (
        <ForgotPasswordModal
          forgotPasswordOpen={forgotPasswordOpen}
          closeForget={closeForget}
          email={email}
          handleEmail={handleEmail}
          submitEmail={submitEmail}
          // emailError={emailError}
        />
      )}
      {passCheck && (
        <SetPassword
          newPasswordOpen={newPasswordOpen}
          handlePassClose={handlePassClose}
          msg={msg}
          email={email}
          handleCode={handleCode}
          token={token}
          handleNewPassword={handleNewPassword}
          password={password}
          submitNewPassword={submitNewPassword}
        />
      )}
    </Box>
  );
};

export default Login;
