import React from 'react';
import { Box, Stack } from "@mui/material";
import Navbar from './Sub-Components/Navbar';
import Sidebar3 from './Sub-Components/Sidebar3';
import ProductMain from './Sub-Components/ProductMain';

const Products = () => {
  return (
    <Box>
        <Navbar />
        <Box sx={{ display: "flex", flexDirection: 'row', padding: '0px', marginTop: '80px', justifyContent: 'center'}}>
            <Sidebar3 />
            <ProductMain />
        </Box>
    </Box>
  )
}

export default Products
