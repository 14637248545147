import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  styled,
  Typography,
  Button,
  Stack,
  Avatar,
} from "@mui/material";
import TopBar from "./TopBar";
import FilterBar from "./FilterBar";
import ProductTable from "./ProductTable";

const ProductMain = () => {
  const [categoryy, setCategoryy] = useState("");
  const [categor, setCategor] = useState([]);
  const [products, setProducts] = useState([]);
  const [checkProducts, setCheckProducts] = useState(false);
  const [checkCat, setCheckCat] = useState(false);
  const [productFilter, setProductFilter] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [error, setError] = useState(false);
  const [error1, setError1] = useState("");
  const [error2, setError2] = useState("");
  const [search, setSearch] = useState("");
  const [data, setData] = useState(products);
  const [createCategoryOpen, setCreateCategoryOpen] = useState(false);
  const [openCat, setOpenCat] = useState(false);

  const category = [categoryName];

  useEffect(() => {
    fetch("https://api.allroundcare.org/utilites/categories")
      .then((res) => {
        if (res.status !== 200) {
          throw Error("Failed to load categories");
        }
        return res.json();
      })
      .then((data) => {
        setCategor(data);
        setCheckCat(true);
      })
      .catch((err) => {
        setError1(err.message);
      });

    fetch("https://api.allroundcare.org/utilites/unlimited_products")
      .then((response) => {
        if (response.status !== 200) {
          throw Error("Failed to load products");
        }
        return response.json();
      })
      .then((dat) => {
        setProducts(dat);
        setCheckProducts(true);
        setProductFilter(true);
      })
      .catch((er) => {
        setError2(er.message);
      });
  }, []);

  const handleChanged = (e) => {
    setCategoryy(e.target.value);
  };
  const handleChange = (value) => {
    setSearch(value);
  };
  const testing = (id) => {
    console.log(id);
  };
  const arr = [];
  const handleFilter = (name) => {
    const pro = products.filter(
      (product) => product.categories.includes(name) === true
    );
    if (pro.length !== 0) {
      setProducts(pro);
    } else {
      setProductFilter(false);
      setProducts(products);
    }
  };
  const handleChangedd = (e) => {
    setCategoryName(e.target.value);
  };
  const openCategory = () => {
    setCreateCategoryOpen(true);
    setOpenCat(true);
  };
  const closeCategory = () => {
    setCreateCategoryOpen(false);
    setOpenCat(false);
    setError(false);
  };
  const userToken = localStorage.getItem("token");
  const headers = new Headers();
  headers.append("Content-type", "application/json");
  headers.append("Authorization", "Bearer" + " " + userToken);
  const handleSubmit = () => {
    console.log("favorite footballer: KDB");
    if (categoryName.length !== 0) {
      fetch("https://api.allroundcare.org/admin/category", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(category),
      }).then((res) => {
        if (res.status === 200) {
          setCreateCategoryOpen(false);
          setOpenCat(false);
          setCategoryName("");
          setError(false);
          window.location.reload();
        } else {
          console.log("it didnt work");
        }
      });
    } else {
      setError(true);
    }
  };

  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "100%", md: "82.64%" },
        backgroundColor: "none",
        px: "25px",
        paddingTop: "32px",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <TopBar
        search={search}
        handleChange={handleChange}
        error={error}
        handleSubmit={handleSubmit}
        handleChangedd={handleChangedd}
        categoryName={categoryName}
        createCategoryOpen={createCategoryOpen}
        openCat={openCat}
        openCategory={openCategory}
        closeCategory={closeCategory}
      />
      <Box
        component="div"
        sx={{
          paddingBottom: "24px",
          paddingTop: "0px",
          paddingRight: "0px",
          paddingLeft: "0px",
          marginTop: "32px",
        }}
      >
        <FilterBar
          categoryy={categoryy}
          handleChanged={handleChanged}
          testing={testing}
          categor={categor}
          checkCat={checkCat}
          handleFilter={handleFilter}
        />
        {checkProducts && (
          <ProductTable
            products={products}
            productFilter={productFilter}
            search={search}
            categoryy={categoryy}
          />
        )}
      </Box>
    </Box>
  );
};

export default ProductMain;
