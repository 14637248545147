import React from "react";
import { Box, Typography } from "@mui/material";
import Navbar from "./Sub-Components/Navbar";
import Sidebar2 from "./Sub-Components/Sidebar2";
import OrderMain from "./Sub-Components/OrderMain";
import { useQuery } from "react-query";

const Orders = () => {
  const token = localStorage.getItem("token");

  const allRetailOrders = async () => {
    return await fetch(`https://api.allroundcare.org/admin/all_orders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      if (res.status !== 200) {
        throw Error("Unable to fetch data from resource");
      }
      return res.json();
    });
  };
  const allCreatedOrders = async () => {
    return await fetch(
      `https://api.allroundcare.org/admin/all_retailer_order`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((res) => {
      if (res.status !== 200) {
        throw Error("Unable to fetch data from resource");
      }
      return res.json();
    });
  };

  const { data: totalOrders, isLoading } = useQuery(
    "all-orders",
    allRetailOrders,
    {
      refetchInterval: 3000,
    }
  );

  const { data: createdOrders, isLoading: createdLoading } = useQuery(
    "created-orders",
    allCreatedOrders,
    {
      refetchInterval: 3000,
    }
  );

  if (isLoading || createdLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h4">Loading.....</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: "0px",
          marginTop: "80px",
          justifyContent: "center",
        }}
      >
        <Sidebar2 />
        <OrderMain allorders={totalOrders} createdOrders={createdOrders} />
      </Box>
    </Box>
  );
};

export default Orders;
