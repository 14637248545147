import React, {useState, useEffect, useCallback, useRef} from "react";
import {Modal, styled, Box, Typography, modalClasses, Stack, Button, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, Select, OutlinedInput, MenuItem, TextField, InputAdornment, IconButton,Radio, RadioGroup, FormControlLabel, FormControl, FormLabel} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import BeatLoader from "react-spinners/BeatLoader";
import AddIcon from '@mui/icons-material/Add';
import ShopperSearch from "./ShopperSearch";


const StyledModal = styled(Modal)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    paddingTop:"60px",
    paddingBottom:"60px",
    overflow:"auto"
});

const AddShopper = ({addShopperOpen, closeAddShopper, searcher, shoppers, handleChangeed, value, handleChanget, handleLet, ini, handlesub}) => {
  return (
    <StyledModal
    open={addShopperOpen}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description">
        <Box sx={{width:{xs: "90vw", sm: "90vw", md:"40vw"},px:{xs:'20px', sm:'20px', md: '44px'}, py:'30px',maxHeight:'90vh',overflow:'auto', backgroundColor:'white', borderRadius:'10px', display:'block'}}>
            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                <Typography variant="h5">Assign Shopper</Typography>
                <CloseIcon sx={{cursor:'pointer'}} onClick={()=>closeAddShopper()}/>
            </Box>
            <ShopperSearch handleChanget={handleChanget}/>
            <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={handleChangeed}
                >
                {shoppers.filter((shopper)=>{
                    return searcher.toLowerCase() === ''? shopper : shopper.first_name.toLowerCase().includes(searcher)
                }).map((shopper)=>(
                    <FormControlLabel onClick={()=>handleLet(shopper.id, shopper)} value={shopper.first_name +" "+shopper.last_name} control={<Radio sx={{
                        color: '#28A745',
                        '&.Mui-checked': {
                          color: '#28A745',
                        },
                      }}/>} label={<Box sx={{display: 'flex', flexDirection:'row', justifyContent:'space-between', width: {xs:'70vw', sm:'60vw', md:'30vw'}}}>
                        <Typography>{shopper.first_name +" "+shopper.last_name}</Typography>
                        <Typography>{shopper.distance}Km</Typography>
                      </Box>} key={shopper.id}/>
                      
                ))}
                </RadioGroup>
            </FormControl>
            {ini === true ? <Button
                variant="contained"
                type="button"
                onClick={()=>handlesub()}
                sx={{width:'100%',height:'44px', backgroundColor:'#28A745', color:'white', mt:'24px', borderRadius:'8px', alignItems:'center', '&:hover': {backgroundColor:'#44ab5c'}}}>
                    Proceed
                </Button> : <Box sx={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center',p:'0px', mt:'24px'}}>
                    <BeatLoader color="#28A745" size={20}/>
                </Box>}
        </Box>
    </StyledModal>
  )
}

export default AddShopper
