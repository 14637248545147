import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  styled,
  Table,
  TableBody,
  Stack,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Popper,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProductDetailsModal from './ProductDetailsModal';
import EditPriceModal from './EditPriceModal';

const ITEM_HEIGHT = 48;

const ProductTable = ({ products, productFilter, search }) => {
  const [newPrice, setNewPrice] = useState([]);
  const [newCat, setNewCat] = useState([]);
  const [newTags, setNewTags] = useState([]);
  const [details, setDetails] = useState({});
  const [detal, setDetal] = useState(false);
  const [viewProductDetailsModalOpen, setViewProductDetailsModalOpen] =
    useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEll, setAnchorEll] = React.useState(null);
  const [anchorElll, setAnchorElll] = React.useState(null);
  const [anchorEllll, setAnchorEllll] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [priceEdite, setPriceEdite] = useState([]);
  const [openPriceEditModal, setOpenPriceEditModal] = useState(false);
  const [editPriceCheck, setEditPriceCheck] = useState(false);
  const open = Boolean(anchorEl);
  const openP = Boolean(anchorEll);
  const openPp = Boolean(anchorElll);
  const openPpp = Boolean(anchorEllll);
  const [id, setId] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (events, id, product) => {
    setDetails(product);
    setAnchorEl(events.currentTarget);
    products.map((pro) => {
      if (pro._id === id) {
        setId(pro._id);
      }
    });
  };
  const priceOpen = (events, id, prices) => {
    setNewPrice(prices);
    products.map((pri) => {
      if (pri._id === id) {
        setAnchorEll(events.currentTarget);
      }
    });
  };
  const catOpened = (events, id, categories) => {
    setNewCat(categories);
    products.map((pri) => {
      if (pri._id === id) {
        setAnchorElll(events.currentTarget);
      }
    });
  };
  const tagOpened = (events, id, tags) => {
    setNewTags(tags);
    products.map((pri) => {
      if (pri._id === id) {
        setAnchorEllll(events.currentTarget);
      }
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloses = () => {
    setAnchorEll(null);
  };
  const handleClosed = () => {
    setAnchorElll(null);
  };
  const handleCloser = () => {
    setAnchorEllll(null);
  };
  const productEdit = (id, product) => {
    setViewProductDetailsModalOpen(true);
    setDetal(true);
  };
  const priceEdit = (id, product) => {
    setPriceEdite(details.prices);
    products.map((product) => {
      if (product.id === id) {
        setPriceEdite(product.prices);
      }
    });
    setOpenPriceEditModal(true);
    setEditPriceCheck(true);
  };
  const handleModalClose = () => {
    setViewProductDetailsModalOpen(false);
    setDetal(false);
  };
  const handleCloseEditModal = () => {
    setOpenPriceEditModal(false);
    setEditPriceCheck(false);
  };

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#28A745' }}>
              <TableCell sx={{ backgroundColor: 'transparent', color: '#fff' }}>
                Product Name/Avi
              </TableCell>
              <TableCell sx={{ backgroundColor: 'transparent', color: '#fff' }}>
                Category
              </TableCell>
              <TableCell sx={{ backgroundColor: 'transparent', color: '#fff' }}>
                Price
              </TableCell>
              <TableCell sx={{ backgroundColor: 'transparent', color: '#fff' }}>
                Tags
              </TableCell>
              <TableCell sx={{ backgroundColor: 'transparent', color: '#fff' }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: '#C7E7CE' }}>
            {productFilter &&
              products
                .filter((product) => {
                  return search.toLowerCase() === ''
                    ? product
                    : product.product_name.toLowerCase().includes(search);
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((product) => (
                  <TableRow key={product.id}>
                    <TableCell>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={
                            product.product_images.length !== 0
                              ? product.product_images[0].src
                              : 'https://via.placeholder.com/24'
                          }
                          alt="img"
                          style={{
                            width: '40px',
                            height: '40px',
                            border: '1px solid transparent',
                            borderRadius: '5px',
                          }}
                        />
                        <Typography variant="p" sx={{ marginLeft: '8px' }}>
                          {product.product_name}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <span
                        onClick={(events) =>
                          catOpened(events, product._id, product.categories)
                        }
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        {product.categories[0]}
                        <ExpandMoreIcon
                          sx={{ color: '#28A745', marginLeft: '5px' }}
                        />
                      </span>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorElll}
                        open={openPp}
                        onClose={handleClosed}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            paddingLeft: '3px',
                            paddingRight: '3px',
                            paddingTop: '3px',
                            paddingBottom: '3px',
                            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
                          },
                        }}
                      >
                        {newCat.map((cat, index) => (
                          <div
                            key={index}
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <MenuItem>
                              <Typography>{cat}</Typography>
                            </MenuItem>
                          </div>
                        ))}
                      </Menu>
                    </TableCell>
                    <TableCell>
                      <span
                        onClick={(events) =>
                          priceOpen(events, product._id, product.prices)
                        }
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        {product.prices[0].amount}
                        <ExpandMoreIcon
                          sx={{ color: '#28A745', marginLeft: '5px' }}
                        />
                      </span>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEll}
                        open={openP}
                        onClose={handleCloses}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            paddingLeft: '3px',
                            paddingRight: '3px',
                            paddingTop: '3px',
                            paddingBottom: '3px',
                            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
                          },
                        }}
                      >
                        {newPrice.map((price) => (
                          <div
                            key={price._id}
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <MenuItem>
                              <Stack direction="column">
                                <Typography
                                  variant="p"
                                  sx={{ fontWeight: 'bold' }}
                                >
                                  <span style={{ fontWeight: '400' }}>
                                    Name:
                                  </span>{' '}
                                  {price.price_name}
                                </Typography>
                                <Typography
                                  variant="p"
                                  sx={{ fontWeight: 'bold' }}
                                >
                                  <span style={{ fontWeight: '400' }}>
                                    Amount:
                                  </span>{' '}
                                  {price.amount}
                                </Typography>
                                <Typography
                                  variant="p"
                                  sx={{ fontWeight: 'bold' }}
                                >
                                  <span style={{ fontWeight: '400' }}>
                                    Size:
                                  </span>{' '}
                                  {price.size}
                                </Typography>
                              </Stack>
                            </MenuItem>
                          </div>
                        ))}
                      </Menu>
                    </TableCell>
                    <TableCell>
                      <span
                        onClick={(events) =>
                          tagOpened(events, product._id, product.tags)
                        }
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        {product.tags[0]}
                        <ExpandMoreIcon
                          sx={{ color: '#28A745', marginLeft: '5px' }}
                        />
                      </span>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEllll}
                        open={openPpp}
                        onClose={handleCloser}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            paddingLeft: '3px',
                            paddingRight: '3px',
                            paddingTop: '3px',
                            paddingBottom: '3px',
                            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
                          },
                        }}
                      >
                        {newTags.map((tag, index) => (
                          <div
                            key={index}
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <MenuItem>
                              <Typography>{tag}</Typography>
                            </MenuItem>
                          </div>
                        ))}
                      </Menu>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(events) =>
                          handleClick(events, product._id, product)
                        }
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
                          },
                        }}
                      >
                        <MenuItem
                          sx={{ borderBottom: '1px solid #28A745' }}
                          onClick={() => productEdit(product._id, product)}
                        >
                          Edit Product Name
                        </MenuItem>
                        <MenuItem
                          onClick={() => priceEdit(product._id, product)}
                        >
                          Edit Product Price
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30, 40]}
        component="div"
        count={products.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {detal && (
        <ProductDetailsModal
          details={details}
          viewProductDetailsModalOpen={viewProductDetailsModalOpen}
          handleModalClose={handleModalClose}
          id={id}
        />
      )}
      {editPriceCheck && (
        <EditPriceModal
          openPriceEditModal={openPriceEditModal}
          details={details}
          handleCloseEditModal={handleCloseEditModal}
          priceEdite={priceEdite}
        />
      )}
    </Paper>
  );
};

export default ProductTable;
