import React from 'react'
import CurrentUserSearch from './CurrentUserSearch';
import { Box,Button, Modal } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';

const TopBar3 = ({addUserOpen}) => {
  return (
    <Box component='div' sx={{backgroundColor: '#C7E7CE', borderRadius:'10px', px:'16px', py:'25px', display: { sm: "block", md:"flex"}, flexDirection: 'row', justifyContent: {sm: 'center', md:'space-between'}, alignItems:'center'}}>
        <CurrentUserSearch />
        <Button 
        variant="contained" 
        startIcon={<AddCircleIcon />} 
        onClick={()=>addUserOpen()}
        sx={{width: { xs:'100%',sm: "100%", md: "177px"}, marginTop:{xs:'8px', sm:'8px', md:'0px'}, marginLeft:{xs:'0px', sm:'0px', md:'8px' }, height: '50px', backgroundColor: '#28A745', borderRadius: '10px', '&:hover': {backgroundColor:'#44ab5c'}}}
        >
            Add Users
        </Button>
    </Box>
  )
}

export default TopBar3
