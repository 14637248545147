import { Box, AppBar,Toolbar,styled, Typography, Button, Stack, Avatar, FormControl, FormGroup,IconButton, Paper, MenuItem, Select,  OutlinedInput,TextField } from "@mui/material";
import React, {useState, useEffect, useCallback, useRef} from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate, useLocation } from "react-router-dom";

const PasswordResetMain = () => {

    const navigate = useNavigate()

  return (
    <Box sx={{width: {xs:'100%', sx:'100%',md:'82.64%' }, backgroundColor: 'none', px: '25px',paddingTop:'32px',paddingBottom:'32px', display:'flex', flexDirection:'column', overflowY:'auto'}}>
        <Box sx={{width:'55%', display:'flex', flexDirection:'row',cursor:'pointer',  justifyContent:'space-between', alignItems:'center'}}>
            <Box onClick={()=>navigate('/settings')} sx={{display:'flex', flexDirection:'row', alignItems:'center'}} >
                <ArrowBackIosIcon sx={{color:'#28A745'}}/>
                <Typography variant='p' sx={{color:'#28A745'}}>back</Typography>
            </Box>
            <Typography variant='h5'>Password Reset</Typography>
        </Box>
        <Box component="form" sx={{display:'flex', flexDirection:'column', alignItems:'center', marginTop:'32px'}}>
            <Box sx={{display:'block', width:'100%', padding:'0px', m:'0px'}}>
                <Typography sx={{color:'#696F79'}}>Current Password</Typography>
                <TextField 
                type="text"
                id="outlined-basic" 
                //value={first_name} 
                //onChange={(e)=>firstNameChange(e)}
                variant="outlined" 
                placeholder= "Enter your current password"
                sx={{border:'1px solid #A1A1A1', borderRadius:'8px', width:'100%'}}
                />
            </Box>
            <Box sx={{display:'block', width:'100%', padding:'0px', marginTop:{xs:'16px', sm:'16px', md:'24px'}}}>
                <Typography sx={{color:'#696F79'}}>New Password</Typography>
                <TextField 
                type="text"
                id="outlined-basic" 
                //value={first_name} 
                //onChange={(e)=>firstNameChange(e)}
                variant="outlined" 
                placeholder= "Enter your new password"
                sx={{border:'1px solid #A1A1A1', borderRadius:'8px', width:'100%'}}
                />
            </Box>
            <Box sx={{display:'block', width:'100%', padding:'0px', marginTop:{xs:'16px', sm:'16px', md:'24px'}}}>
                <Typography sx={{color:'#696F79'}}>Confirm new Password</Typography>
                <TextField 
                type="text"
                id="outlined-basic" 
                //value={first_name} 
                //onChange={(e)=>firstNameChange(e)}
                variant="outlined" 
                placeholder= "Confirm your new password"
                sx={{border:'1px solid #A1A1A1', borderRadius:'8px', width:'100%'}}
                />
            </Box>
            <Button
            type="submit"
            variant="contained"
            sx={{width: {xs:'100%', sm:'100%', md:'50%'}, marginTop:'24px', borderRadius:'10px', height:'48px', backgroundColor: '#28A745', '&:hover': {backgroundColor:'#44ab5c'}}}>
                Change Password
            </Button>
        </Box>
    </Box>
  )
}

export default PasswordResetMain
