import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';

export default function AcceptedShopper({ acceptedShopper }) {
  return (
    <>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#28A745' }}>
                <TableCell
                  sx={{ backgroundColor: 'transparent', color: '#fff' }}
                >
                  Order ID
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: 'transparent', color: '#fff' }}
                >
                  Date
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: 'transparent', color: '#fff' }}
                >
                  Total Product
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: 'transparent', color: '#fff' }}
                >
                  Retailer Address
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {acceptedShopper.length > 0 ? (
                acceptedShopper.map((orders) => {
                  const { _id, products, retailer_address, createdAt } = orders;
                  const formatDate = (dateString) => {
                    const options = {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    };

                    return new Date(dateString).toLocaleDateString(
                      undefined,
                      options
                    );
                  };

                  const date = formatDate(createdAt);
                  return (
                    <TableRow>
                      {/* <TableCell>{orders._id}</TableCell> */}
                      <TableCell>{_id}</TableCell>
                      <TableCell>{date}</TableCell>
                      <TableCell>{products.length}</TableCell>
                      <TableCell>{retailer_address}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <Typography>No orders currently</Typography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
