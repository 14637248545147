import React from 'react';
import './Chart.css';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
} from 'chart.js';

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip
);

export default function Chart() {
  const data = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    datasets: [
      {
        label: 'First dataset',
        data: [2, 4, 6, 7.5, 8, 7.5, 3.5, 3, 3.5, 5.4, 9],
        backgroundColor: '#C7E7CE',
        borderColor: '#28A745',
        pointBorderColor: '#C7E7CE',
        tension: 0.4,
      },
    ],
  };

  const options = {
    plugins: {
      legend: true,
    },
    scales: {
      y: {
        min: 1,
        max: 10,
      },
    },
  };

  return (
    <>
      <div className="ChartContainer">
        <h2>Revenue</h2>
        <div style={{ width: '500px', height: '300px' }}>
          <Line data={data} options={options} />
        </div>
      </div>
    </>
  );
}
