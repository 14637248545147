import { Box, Select, MenuItem, FormControl, InputLabel, Stack, Typography, MenuList } from "@mui/material";

const FilterBar = ({categoryy, handleChanged, testing, categor, checkCat, handleFilter}) => {
    
    return (
        <Box components="div" sx={{padding: '16px', margin: '0px', backgroundColor: '#C7E7CE', display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
        <FormControl >
        <InputLabel id="demo-simple-select-label">Category</InputLabel>
        <Select 
          labelId="demo-simple-select-label" 
          id="demo-simple-select" 
          value={categoryy} 
          label="Category"
          sx={{width:'200px', height: '50px', backgroundColor: 'white', borderRadius:'10px'}}
          onChange={(e)=>handleChanged(e)}
        > 
        {checkCat && categor.map((cate)=>(
                <MenuItem key={cate.id} value={cate.name}>{cate.name}</MenuItem>
        ))}
        </Select>
      </FormControl>
        </Box>
     );
}
 
export default FilterBar;