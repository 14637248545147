import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Modal,
  styled,
  Box,
  Typography,
  modalClasses,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  price_name: yup.string().required("price is required"),
  amount: yup.number().required().positive().integer(),
  size: yup.string().required("size is required"),
});

const StyledModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  //paddingTop:"150px",
  //paddingBottom:"60px",
  overflow: "auto",
});

const EditPriceModal = ({
  openPriceEditModal,
  details,
  handleCloseEditModal,
  priceEdite,
}) => {
  const [price_name, setPrice_name] = useState("");
  const [amount, setAmount] = useState();
  const [size, setSize] = useState();
  const [newId, setNewId] = useState();

  const edit = (id) => {
    console.log(id);
    //let id = id;
    const data = { price_name, amount, size };
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const handleChange1 = (id) => {
    console.log(id);
    setNewId(id);
  };
  const handleChange2 = (e) => {
    setAmount(e.target.value);
  };
  const handleChange3 = (e) => {
    setSize(e.target.value);
  };
  const headers = new Headers();
  const userToken = localStorage.getItem("token");
  headers.append("Authorization", "Bearer" + " " + userToken);
  headers.append("Content-type", "application/json");
  const submit = (data) => {
    console.log(data);
    console.log(newId);
    priceEdite.map((pri) => {
      if (pri._id === newId) {
        fetch(
          `https://api.allroundcare.org/admin/update_product_price?id=${newId}`,
          {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(data),
          }
        ).then((res) => {
          if (res.status !== 200) {
            console.log("e no work oo");
          }
        });
      }
    });
    //console.log(id)
  };
  return (
    <StyledModal
      open={openPriceEditModal}
      onClose={handleCloseEditModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          width: { xs: "93vw", sm: "93vw", md: "40vw" },
          px: "44px",
          py: "30px",
          backgroundColor: "white",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Edit Product price
          </Typography>
          <CloseIcon
            sx={{ cursor: "pointer" }}
            onClick={() => handleCloseEditModal()}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            p: "0px",
            display: "flex",
            flexDirection: "column",
            marginTop: "16px",
          }}
        >
          {priceEdite.map((pridit) => (
            <Box
              key={pridit._id}
              sx={{ width: "100%", display: "flex", flexDirection: "column" }}
            >
              <Box
                sx={{
                  borderBottom: "1px dashed rgba(0,0,0,0.3)",
                  py: "5px",
                  px: "0px",
                  margin: "0px",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    textTransform: "capitalize",
                  }}
                >
                  {pridit.price_name}
                </Typography>
              </Box>
              <form
                onSubmit={handleSubmit(submit)}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "8px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: { xs: "column", sm: "column", md: "row" },
                    justifyContent: {
                      xs: "none",
                      sm: "none",
                      md: "space-between",
                    },
                  }}
                >
                  <Box sx={{ display: "block" }}>
                    <Typography sx={{ color: "rgba(0,0,0,0.7)" }}>
                      Price Name
                    </Typography>
                    <TextField
                      required
                      //name="price_name"
                      type="text"
                      id="outlined-required"
                      placeholder={pridit.price_name}
                      {...register("price_name")}
                      //value={price_name}
                      onChange={() => handleChange1(pridit._id)}
                      sx={{ borderRadius: "8px", height: "44px", width: "95%" }}
                    />
                  </Box>
                  <Box sx={{ display: "block" }}>
                    <Typography sx={{ color: "rgba(0,0,0,0.7)" }}>
                      Amount
                    </Typography>
                    <TextField
                      required
                      //name="amount"
                      type="number"
                      id="outlined-required"
                      placeholder={pridit.amount}
                      {...register("amount")}
                      //value={amount}
                      //onChange={(e)=>handleChange2(e)}
                      sx={{ borderRadius: "8px", height: "44px", width: "95%" }}
                    />
                  </Box>
                  <Box sx={{ display: "block" }}>
                    <Typography sx={{ color: "rgba(0,0,0,0.7)" }}>
                      Size
                    </Typography>
                    <TextField
                      required
                      //name="size"
                      type="number"
                      id="outlined-required"
                      {...register("size")}
                      placeholder={pridit.size}
                      //value={size}
                      //onChange={(e)=>handleChange3(e)}
                      sx={{ borderRadius: "8px", height: "44px", width: "95%" }}
                    />
                  </Box>
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  //onClick={()=>edit(pridit._id)}
                  sx={{
                    width: "50%",
                    height: "44px",
                    backgroundColor: "#28A745",
                    color: "white",
                    mt: "24px",
                    borderRadius: "8px",
                    alignItems: "center",
                    "&:hover": { backgroundColor: "#44ab5c" },
                  }}
                >
                  Edit Price
                </Button>
              </form>
            </Box>
          ))}
        </Box>
      </Box>
    </StyledModal>
  );
};

export default EditPriceModal;
