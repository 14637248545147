import React from 'react';
import { Box, Stack } from '@mui/material';
import CurrentUserMain from './Sub-Components/CurrentUserMain';
import Navbar from './Sub-Components/Navbar';
import Sidebar7 from './Sub-Components/SideBar7';

const CurrentUser = () => {
  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '0px',
          marginTop: '80px',
          justifyContent: 'center',
        }}
      >
        <Sidebar7 />
        <CurrentUserMain />
      </Box>
    </Box>
  );
};

export default CurrentUser;
