import { Box } from '@mui/material';
import DashbordMain from './Sub-Components/DashbordMain';
import Navbar from './Sub-Components/Navbar';
import Sidebar from './Sub-Components/SideBar';

const Dashboard = () => {
  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '15rem auto',
          padding: '0px',
          marginTop: '80px',
          gap: '1rem',
        }}
      >
        <Sidebar />
        <DashbordMain />
      </Box>
    </Box>
  );
};

export default Dashboard;
