import React from 'react';
import { Box, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ReactComponent as Preferences } from '../../Svg/Preferences.svg';
import { ReactComponent as Security } from '../../Svg/Security.svg';
import { useNavigate } from 'react-router-dom';

const SettingsMain = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '100%', md: '82.64%' },
        display: 'block',
        px: '25px',
        paddingTop: '32px',
        paddingBottom: '32px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'column', md: 'row' },
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          onClick={() => navigate('/profileinformation')}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
            backgroundColor: '#C7E7CE',
            borderRadius: '10px',
            cursor: 'pointer',
            width: { xs: '100%', sm: '100%', md: '32%' },
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              m: '0px',
              p: '0px',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                backgroundColor: 'rgba(125, 170, 131, 0.5)',
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                display: 'flex',
              }}
            >
              <PersonIcon
                sx={{
                  margin: 'auto',
                  width: '32px',
                  height: '32px',
                  color: '#28A745',
                }}
              />
            </div>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '16px',
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: '#28A745', fontWeight: '500' }}
            >
              Account Information
            </Typography>
            <ArrowForwardIosIcon />
          </Box>
          <Box sx={{ width: '100%', marginTop: '16px', p: '0px' }}>
            <Typography
              variant="p"
              sx={{ color: '#595858', fontWeight: '400' }}
            >
              Review and change your account<br></br> information
            </Typography>
          </Box>
        </Box>
        <Box
          onClick={() => navigate('/preference')}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
            backgroundColor: '#C7E7CE',
            borderRadius: '10px',
            cursor: 'pointer',
            marginTop: { xs: '16px', sm: '16px', md: '0px' },
            width: { xs: '100%', sm: '100%', md: '32%' },
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              m: '0px',
              p: '0px',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                backgroundColor: 'rgba(125, 170, 131, 0.5)',
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                display: 'flex',
              }}
            >
              <Preferences
                style={{
                  margin: 'auto',
                  width: '32px',
                  height: '32px',
                  color: '#28A745',
                }}
              />
            </div>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '16px',
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: '#28A745', fontWeight: '500' }}
            >
              Preferences
            </Typography>
            <ArrowForwardIosIcon />
          </Box>
          <Box sx={{ width: '100%', marginTop: '16px', p: '0px' }}>
            <Typography
              variant="p"
              sx={{ color: '#595858', fontWeight: '400' }}
            >
              Set notification and other preferneces for your account
            </Typography>
          </Box>
        </Box>
        <Box
          onClick={() => navigate('/passwordreset')}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
            backgroundColor: '#C7E7CE',
            borderRadius: '10px',
            cursor: 'pointer',
            marginTop: { xs: '16px', sm: '16px', md: '0px' },
            width: { xs: '100%', sm: '100%', md: '32%' },
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              m: '0px',
              p: '0px',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                backgroundColor: 'rgba(125, 170, 131, 0.5)',
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                display: 'flex',
              }}
            >
              <Security
                style={{
                  margin: 'auto',
                  width: '32px',
                  height: '32px',
                  color: '#28A745',
                }}
              />
            </div>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '16px',
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: '#28A745', fontWeight: '500' }}
            >
              Password Reset
            </Typography>
            <ArrowForwardIosIcon />
          </Box>
          <Box sx={{ width: '100%', marginTop: '16px', p: '0px' }}>
            <Typography
              variant="p"
              sx={{ color: '#595858', fontWeight: '400' }}
            >
              Change your account security information
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsMain;
