import React, { useState } from "react";
import {
  Modal,
  styled,
  Box,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import SearchShopper from "./SearchShopper";
import BeatLoader from "react-spinners/BeatLoader";
import "react-toastify/dist/ReactToastify.css";

const StyledModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "60px",
  paddingBottom: "60px",
  overflow: "auto",
});

const ViewRecipt = ({
  assignModal,
  assignClose,
  receiptItem,
  unassignOrder,
}) => {
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  const { image } = receiptItem;

  const url = `https://api.allroundcare.org/admin/accept_reciept?id=${unassignOrder._id}`;

  const handlesub = () => {
    setLoading(true);
    const headers = new Headers();
    headers.append("Authorization", "Bearer" + " " + token);

    fetch(url, {
      method: "PUT",
      headers: headers,
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        alert("Order completed successfully");
        assignClose();
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <StyledModal
      open={assignModal}
      onClose={assignClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          width: { xs: "90vw", sm: "90vw", md: "40vw" },
          maxHeight: "90vh",
          overflow: "auto",
          px: { xs: "20px", sm: "20px", md: "44px" },
          py: { xs: "20px", sm: "20px", md: "30px" },
          backgroundColor: "white",
          borderRadius: "10px",
          display: "block",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ fontSize: "24px" }}>
            Receipt Submitted By Dispatch Rider
          </Typography>
          <CloseIcon sx={{ cursor: "pointer" }} onClick={() => assignClose()} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={image}
            alt={""}
            style={{
              width: "300px",
              height: "300px",
              objectFit: "contain",
              margin: "0 auto",
            }}
          />
          {!loading && (
            <Button
              variant="contained"
              sx={{
                width: "100%",
                height: "44px",
                backgroundColor: "#28A745",
                color: "white",
                mt: "24px",
                borderRadius: "8px",
                alignItems: "center",
                "&:hover": { backgroundColor: "#44ab5c" },
              }}
              onClick={handlesub}
            >
              Confirm Payment
            </Button>
          )}
          {loading && (
            <Button
              variant="contained"
              sx={{
                width: "100%",
                height: "44px",
                backgroundColor: "#28A745",
                color: "white",
                mt: "24px",
                borderRadius: "8px",
                alignItems: "center",
                "&:hover": { backgroundColor: "#44ab5c" },
              }}
              disabled
            >
              Confirming Payment
            </Button>
          )}
        </Box>
      </Box>
    </StyledModal>
  );
};

export default ViewRecipt;
