import React, { useState } from "react";
import { useEffect } from "react";
import "./OrderedProduct.css";

export default function OrderedProduct() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch("https://api.allroundcare.org/utilites/unlimited_products")
      .then((response) => {
        if (response.status !== 200) {
          throw Error("Failed to load products");
        }
        return response.json();
      })
      .then((data) => {
        setProducts(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  return (
    <>
      <div className="OrderedProduct">
        <h2>Most ordered products</h2>
        {products.map((product) => {
          return (
            <div className="OrderedProduct_details" key={product.id}>
              <img
                className="OrderedProduct_img"
                src={product.product_images[0].src}
                alt={product.product_name}
              />
              <div>
                <h4>{product.product_name}</h4>
                <p style={{ color: "#28A745" }}>{product.prices[0].amount}</p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
