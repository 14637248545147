import React, {useState, useEffect, useCallback, useRef} from "react";
import {Modal, styled, Box, Typography, modalClasses, Stack, Button} from '@mui/material';
import ClockLoader from "react-spinners/ClockLoader";

const StyledModal = styled(Modal)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    //paddingTop:"150px",
    //paddingBottom:"60px",
    overflow:"auto"
});

const LoadingModal = ({loadingModalOpen, loadingModalClose}) => {
  return (
    <StyledModal 
        open={loadingModalOpen}
        //onClose={loadingModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', backgroundColor:'transparent'}}>
              <ClockLoader color="#28A745" size={150} />
              <Typography variant="p" sx={{color:'white', fontSize:'18px'}}>Adding Products...</Typography>
            </Box>
    </StyledModal>
  )
}

export default LoadingModal
