import React, {useState, useEffect, useCallback, useRef} from "react";
import {Modal, styled, Box, Typography, modalClasses, Stack, Button} from '@mui/material';
import {ReactComponent as ErrorMessage} from '../../Svg/ErrorMessage.svg'


const StyledModal = styled(Modal)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    //paddingTop:"150px",
    //paddingBottom:"60px",
    overflow:"auto"
});

const ErrorModal = ({errorModalOpen, errorModalClose, postError}) => {
  return (
    <StyledModal 
        open={errorModalOpen}
        onClose={errorModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box sx={{padding:'20px', width:'20vw', backgroundColor:'white', border:'1px solid transparent', borderRadius:'10px', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                <Box sx={{padding:'0', margin:'0', width:'50px', height:'45px'}}>
                    <ErrorMessage />
                </Box>
                <Typography sx={{fontSize:'18px', marginTop:'16px'}}>Request failed!</Typography>
            </Box>
    </StyledModal>
  )
}

export default ErrorModal


