import React, { useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  styled,
  Typography,
  Stack,
  Avatar,
} from "@mui/material";
import { ReactComponent as Arclogo } from "../../Images/Arclogo.svg";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuIcon from "@mui/icons-material/Menu";
import NavigationModal from "./NavigationModal";
import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "white",
});

const Navbar = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [navCheck, setNavCheck] = useState(false);
  const [user, setUser] = useState({});

  const userTok = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${userTok}`,
  };

  useEffect(() => {
    axios
      .get("https://api.allroundcare.org/accounts/get_user", { headers })
      .then((response) => {
        if (response.status !== 200) {
          toast.error(response.data, {
            position: "top-center",
            autoClose: 500,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
        return response.data;
      })
      .then((data) => {
        setUser(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const toggleButton = () => {
    setNavOpen(true);
    setNavCheck(true);
  };
  const navClose = () => {
    setNavOpen(false);
    setNavCheck(false);
  };
  return (
    <AppBar position="fixed" sx={{ backgroundColor: "white" }}>
      <StyledToolbar>
        <Arclogo />
        <Box
          component="div"
          sx={{
            alignItems: "center",
            flexDirection: "row",
            display: { xs: "none", sm: "none", md: "flex" },
          }}
        >
          <NotificationsIcon sx={{ color: "black" }} />
          <Avatar
            src={user.profile_pics ? user.profile_pics : "/broken-image.jpg"}
            sx={{ marginLeft: "5px" }}
          />
          <Box sx={{ marginLeft: "5px" }}>
            <Stack>
              <Typography variant="h6" sx={{ color: "black" }}>
                {user.first_name} {user.last_name}
              </Typography>
              <Typography variant="p" sx={{ color: "#069426" }}>
                {user.type}
              </Typography>
            </Stack>
          </Box>
        </Box>
        <MenuIcon
          sx={{ color: "black", display: { sm: "block", md: "none" } }}
          onClick={() => toggleButton()}
        />
      </StyledToolbar>
      {navCheck && <NavigationModal navClose={navClose} navOpen={navOpen} />}
    </AppBar>
  );
};

export default Navbar;
