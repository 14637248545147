import React, {useState, useEffect, useCallback, useRef} from "react";
import { Box,styled, Table, TableBody,Stack, TableCell, TableContainer, TableHead, TableRow, Paper, Popper, Typography, Button, IconButton, Menu, MenuItem, TablePagination } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';


const ITEM_HEIGHT = 48;

const RetailerTable = ({retailer}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClick = (events) => {
    setAnchorEl(events.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Paper>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow sx={{backgroundColor: '#28A745'}}>
                        <TableCell sx={{backgroundColor:'transparent' , color:'#fff'}}>Name/Avi</TableCell>
                        <TableCell sx={{backgroundColor:'transparent' , color:'#fff'}}>Phone number</TableCell>
                        <TableCell sx={{backgroundColor:'transparent' , color:'#fff'}}>Email</TableCell>
                        <TableCell sx={{backgroundColor:'transparent' , color:'#fff'}}>Status</TableCell>
                        <TableCell sx={{backgroundColor:'transparent' , color:'#fff'}}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={{backgroundColor:'#C7E7CE'}}>
                    {retailer.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((retailer)=>(
                        <TableRow key={retailer.id}>
                            <TableCell>
                                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                    <img src={retailer.profile_pics !== null ? retailer.profile_pics : 'https://via.placeholder.com/24' } alt="image" style={{width:'40px',height:'40px', border:'1px solid transparent', borderRadius:'5px'}}/>
                                    <Typography variant="p" sx={{marginLeft:'8px'}}>{retailer.first_name + " " + retailer.last_name}</Typography>
                                </div>
                            </TableCell>
                            <TableCell>{retailer.phone}</TableCell>
                            <TableCell>{retailer.email}</TableCell>
                            <TableCell>{retailer.active === 1 ? <Box sx={{padding:'3px', backgroundColor:'#BFF3CB', display:'flex', borderRadius:'8px'}}>
                                <Typography variant="p" sx={{color:'#28A745',margin:'auto'}}>active</Typography>
                            </Box> : <Box sx={{padding:'3px', backgroundColor:'#F0D1D1', display:'flex', borderRadius:'8px'}}>
                                <Typography variant="p" sx={{color:'#FB0000',margin:'auto'}}>inactive</Typography>
                            </Box>
                            }</TableCell>
                            <TableCell>
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? 'long-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={(events)=>handleClick(events)}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                  'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                  style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                    boxShadow:'2px 2px 4px rgba(0, 0, 0, 0.1)'
                                  },
                                }}>
                                   <MenuItem>De-Activate User</MenuItem>
                            </Menu>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination 
        rowsPerPageOptions={[10, 20, 30, 40]}
        component="div"
        count={retailer.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </Paper>
  )
}

export default RetailerTable
