import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  styled,
  Typography,
  Button,
  Stack,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import TopBar3 from "./TopBar3";
import CreateUserModal from "./CreateUserModal";
import ShopperTable from "./ShopperTable";
import RetailerTable from "./RetailerTable";
import LogisticTable from "./LogisticsTable";

const CurrentUserMain = () => {
  const token = localStorage.getItem("token");

  const [user, setUser] = useState("");
  const [createUserOpen, setCreateUserOpen] = useState(false);
  const [userCheck, setUserCheck] = useState(false);
  const [type, setType] = useState("");
  const [ret, setRet] = useState(true);
  const [sho, setSho] = useState(false);
  const [log, setLog] = useState(false);
  const [shopper, setShopper] = useState([]);
  const [retailer, setRetailer] = useState([]);
  const [logistic, setLogistic] = useState([]);
  const role = type;

  const handleRet = () => {
    setRet(true);
    setSho(false);
    setLog(false);
  };
  const handleSho = () => {
    setRet(false);
    setSho(true);
    setLog(false);
  };
  const handleLog = () => {
    setLog(true);
    setRet(false);
    setSho(false);
  };

  const handleChange = (e) => {
    setUser(e.target.value);
  };
  const addUserOpen = () => {
    setUserCheck(true);
    setCreateUserOpen(true);
  };
  const closeCreateUser = () => {
    setUserCheck(false);
    setCreateUserOpen(false);
  };
  const handleUserType = (e) => {
    setType(e.target.value);
  };
  useEffect(() => {
    fetch("https://api.allroundcare.org/users/shoppers")
      .then((res) => {
        if (res.status !== 200) {
          throw Error("failed to fetch");
        }
        return res.json();
      })
      .then((data) => {
        setShopper(data.body);
      })
      .catch((err) => {
        console.log(err.message);
      });

    fetch("https://api.allroundcare.org/users/retialers")
      .then((res) => {
        if (res.status !== 200) {
          throw Error("failed to fetch retailers");
        }
        return res.json();
      })
      .then((data) => {
        setRetailer(data.body);
      });

    fetch("https://api.allroundcare.org/admin/all_dispatch_drivers", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw Error("failed to fetch retailers");
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setLogistic(data);
      });
  }, [token]);

  return (
    <Box
      sx={{
        width: { xs: "100%", sx: "100%", md: "82.64%" },
        px: "25px",
        paddingTop: "32px",
        paddingBottom: "32px",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <TopBar3 addUserOpen={addUserOpen} />
      <Box
        component="div"
        sx={{
          paddingBottom: "24px",
          paddingTop: "0px",
          paddingRight: "0px",
          paddingLeft: "0px",
          marginTop: "32px",
        }}
      >
        <Box
          sx={{
            padding: "16px",
            margin: "0px",
            backgroundColor: "#C7E7CE",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <FormControl>
            <InputLabel id="demo-simple-select-label">Select User</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={user}
              label="User"
              sx={{
                width: "200px",
                height: "50px",
                backgroundColor: "white",
                borderRadius: "10px",
              }}
              onChange={(e) => handleChange(e)}
            >
              <MenuItem value={"Retailer"} onClick={() => handleRet()}>
                Retailer
              </MenuItem>
              <MenuItem value={"Shopper"} onClick={() => handleSho()}>
                Shopper
              </MenuItem>
              <MenuItem value={"Logistic"} onClick={() => handleLog()}>
                Logistic
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        {ret && <RetailerTable retailer={retailer} />}
        {sho && <ShopperTable shopper={shopper} />}
        {log && <LogisticTable logistic={logistic} />}
      </Box>
      {userCheck && (
        <CreateUserModal
          createUserOpen={createUserOpen}
          closeCreateUser={closeCreateUser}
          type={type}
          handleUserType={handleUserType}
          role={role}
        />
      )}
    </Box>
  );
};

export default CurrentUserMain;
