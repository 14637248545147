import "./App.css";
import { BrowserRouter as BRouter } from "react-router-dom";
import Router from "./Routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BRouter>
        <Router />
        <ToastContainer />
      </BRouter>
    </QueryClientProvider>
  );
}

export default App;
