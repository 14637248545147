import React from 'react';
import { Box, AppBar,Toolbar,styled, Typography, Button, Stack, Avatar, FormControl, FormGroup,IconButton, Modal, Paper, MenuItem, Select,  OutlinedInput,TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


const StyledModal = styled(Modal)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    paddingTop:"60px",
    paddingBottom:"60px",
    overflow:"auto"
});

const ReasonModal = ({decOpen, decClose, decl}) => {
  return (
    <StyledModal
    open={decOpen}
    onClose={decClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description">
        <Box sx={{width:{xs: "90vw", sm: "90vw", md:"40vw"},maxHeight:'90vh', overflow:'auto',px:{xs:'20px', sm:'20px', md: '44px'}, py:{xs:'20px', sm:'20px', md:'30px'}, backgroundColor:'white', borderRadius:'10px', display:'block'}}>
            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                <Typography variant="h5">Reason for decline</Typography>
                <CloseIcon sx={{cursor:'pointer'}} onClick={()=>decClose()}/>
            </Box>
            <Box sx={{width:'100%', padding:'0px', marginTop:'16px'}}>
                {decl.status.map((dec)=>(
                    <Typography>{dec.name === "declined by shopper" ? dec.reason : null}</Typography>
                ))}
            </Box>
        </Box>
    </StyledModal>
  )
}

export default ReasonModal
