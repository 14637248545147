import { Box } from '@mui/system';
import React from 'react';
import Navbar from './Navbar';
import PreferencesMain from './PreferencesMain';
import Sidebar5 from './SideBar5';

export default function Preferences() {
  return (
    <>
      <Box>
        <Navbar />
        <Box
          sx={{
            display: 'flex',
            marginTop: '6.3%',
          }}
        >
          <Sidebar5 />
          <PreferencesMain />
        </Box>
      </Box>
    </>
  );
}
