import React, {useState, useEffect, useCallback, useRef} from "react";
import {Modal, styled, Box, Typography, modalClasses, Stack, Button, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, Select, OutlinedInput, MenuItem, TextField, InputAdornment, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import BeatLoader from "react-spinners/BeatLoader";
import AddIcon from '@mui/icons-material/Add';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const StyledModal = styled(Modal)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    paddingTop:"60px",
    paddingBottom:"60px",
    overflow:"auto"
});

const schema = yup.object().shape({
    email: yup.string().email().required("Email is required"),
    phone: yup.number().required("phone number is required"),
    address: yup.string().required("Retailer Address is required"),
    first_name: yup.string(),
    last_name: yup.string()
})

const AddRetAdd = ({openAdd, addRetNewClose, init, subb, retailer_address, addressChang}) => {

    const {register, handleSubmit, formState:{errors}} = useForm({
        resolver: yupResolver(schema)
    });


  return (
    <StyledModal
    open={openAdd}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description">
        <Box sx={{width:{xs: "93vw", sm: "93vw", md:"40vw"},px:{xs:'20px', sm:'20px', md: '44px'}, py:'30px',maxHeight:'90vh',overflow:'auto', backgroundColor:'white', borderRadius:'10px', display:'block'}}>
            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                <Typography variant='h5' sx={{fontWeight:'bold'}}>Add New Retailer</Typography>
                <CloseIcon sx={{cursor:'pointer'}} onClick={()=>addRetNewClose()}/>
            </Box>
            <Box sx={{marginTop:'16px',width:'100%'}}>
                <Typography variant="p" sx={{color:'rgba(0,0,0, 0.5)', fontWeight:'500'}}>*Fields are required</Typography>
            </Box>
            <Box component="form" onSubmit={handleSubmit(subb)} sx={{marginTop:'16px', display:'block', width:'100%', p:'0px'}}>
                <Box sx={{width:'100%'}}>
                    <Typography sx={{color:'rgba(0,0,0, 0.5)', fontWeight:'bold'}}>Email Address*</Typography>
                    <TextField 
                    required
                    type="Email"
                    id="outlined-basic" 
                    //value={product_name} 
                    //onChange={(e)=>productChange(e)}
                    {...register("email")}
                    variant="outlined" 
                    placeholder='Enter Email Address' 
                    sx={{border:'1px solid #A1A1A1', borderRadius:'8px', width:'100%'}}
                    />
                    <Typography variant="p" sx={{color:'red', maginTop:'8px'}}>{errors.email?.message}</Typography>
                </Box>
                <Box sx={{width:'100%', display:'block', mt:'16px', p:'0'}}>
                    <Typography sx={{color:'rgba(0,0,0, 0.5)', fontWeight:'bold'}}>Phone number*</Typography>
                    <TextField 
                    required
                    type="number"
                    id="outlined-basic" 
                    //value={product_name} 
                    //onChange={(e)=>productChange(e)}
                    {...register("phone")}
                    variant="outlined" 
                    placeholder='Enter phone number' 
                    sx={{border:'1px solid #A1A1A1', borderRadius:'8px', width:'100%'}}
                    />
                    <Typography sx={{color:'red', maginTop:'8px'}}>{errors.phone?.message}</Typography>
                </Box>
                <Box sx={{width:'100%', display:'block', mt:'16px', p:'0'}}>
                    <Typography sx={{color:'rgba(0,0,0, 0.5)', fontWeight:'bold'}}>First Name</Typography>
                    <TextField 
                    type="text"
                    id="outlined-basic" 
                    //value={product_name} 
                    //onChange={(e)=>productChange(e)}
                    {...register("first_name")}
                    variant="outlined" 
                    placeholder='Enter first name' 
                    sx={{border:'1px solid #A1A1A1', borderRadius:'8px', width:'100%'}}
                    />
                </Box>
                <Box sx={{width:'100%', display:'block', mt:'16px', p:'0'}}>
                    <Typography sx={{color:'rgba(0,0,0, 0.5)', fontWeight:'bold'}}>Last Name</Typography>
                    <TextField 
                    type="text"
                    id="outlined-basic" 
                    //value={product_name} 
                    //onChange={(e)=>productChange(e)}
                    {...register("last_name")}
                    variant="outlined" 
                    placeholder='Enter last name' 
                    sx={{border:'1px solid #A1A1A1', borderRadius:'8px', width:'100%'}}
                    />
                </Box>
                <Box sx={{display:'block', mt:'16px', p:'0'}}>
                    <Typography sx={{color:'rgba(0,0,0, 0.5)', fontWeight:'bold'}}>Retailer Address*</Typography>
                    <TextField 
                    required
                    sx={{border:'1px solid #A1A1A1', borderRadius:'8px', width:'100%'}}
                    id="outlined-multiline-static"
                    multiline
                    type="text"
                    value={retailer_address}
                    {...register("address")}
                    rows={2}
                    placeholder="Enter Retailer address"
                    onChange={(e)=>addressChang(e)}
                    />
                    <Typography sx={{color:'red', maginTop:'8px'}}>{errors.address?.message}</Typography>
                </Box>
                {init === true ? <Button 
                variant="Conatined"
                type="submit"
                //onClick={()=>handleNex()}
                sx={{color:'white', height: '50px', width:'100%',backgroundColor: '#28A745',marginTop:'24px', borderRadius: '10px', '&:hover': {backgroundColor:'#44ab5c'}}}>
                    Next
                </Button> : <Box sx={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center',p:'0px', mt:'24px'}}>
                    <BeatLoader color="#28A745" size={20}/>
                </Box>}
            </Box>
        </Box>
    </StyledModal>
  )
}

export default AddRetAdd
