import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function PreferencesMain() {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <Box
          sx={{
            width: '120%',
            display: 'flex',
            cursor: 'pointer',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '5%',
          }}
        >
          <Box
            onClick={() => navigate('/settings')}
            sx={{
              display: 'flex',
              marginLeft: '10%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ArrowBackIosIcon sx={{ color: '#28A745' }} />
            <Typography variant="p" sx={{ color: '#28A745' }}>
              back
            </Typography>
          </Box>
          <Typography variant="h5">Preferences</Typography>
        </Box>
        <Box
          sx={{
            marginLeft: '25%',
            marginTop: '10%',
            width: '100%',
          }}
        >
          <p
            style={{
              fontSize: '18px',
            }}
          >
            How do you want to receive notifications?
          </p>
          <input
            type="radio"
            name="gender"
            style={{
              cursor: 'pointer',
            }}
          />
          <label
            style={{
              marginLeft: '10px',
            }}
          >
            Via Email Only
          </label>
          <br />
          <br />
          <input
            type="radio"
            name="gender"
            style={{
              cursor: 'pointer',
            }}
          />
          <label
            style={{
              marginLeft: '10px',
            }}
          >
            Via SMS Only
          </label>
          <br />
          <br />
          <input
            type="radio"
            name="gender"
            style={{
              cursor: 'pointer',
            }}
          />
          <label
            style={{
              marginLeft: '10px',
            }}
          >
            Both Email and SMS
          </label>

          <p
            style={{
              fontSize: '18px',
            }}
          >
            Do you want to receive notifications on new order?
          </p>
          <input
            type="radio"
            name="new order"
            style={{
              cursor: 'pointer',
            }}
          />
          <label
            style={{
              marginLeft: '10px',
            }}
          >
            Yes
          </label>
          <br />
          <br />
          <input
            type="radio"
            name="new order"
            style={{
              cursor: 'pointer',
            }}
          />
          <label
            style={{
              marginLeft: '10px',
            }}
          >
            No
          </label>
        </Box>
      </div>
    </>
  );
}
