import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Modal,
  styled,
  Box,
  Typography,
  modalClasses,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const StyledModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  //paddingTop:"150px",
  //paddingBottom:"60px",
  overflow: "auto",
});

const ProductDetailsModal = ({
  details,
  viewProductDetailsModalOpen,
  handleModalClose,
  id,
}) => {
  const [product_name, setProduct_name] = useState("");
  const [fullDescription, setFullDescription] = useState("");
  const shortDescription = fullDescription.slice(0, 20);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);

  const userToken = localStorage.getItem("token");
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer" + " " + userToken);

  const handleChange = (e) => {
    setProduct_name(e.target.value);
  };
  const handleDes = (e) => {
    setFullDescription(e.target.value);
  };
  const handleSub = () => {
    if (product_name.length === 0 && fullDescription.length === 0) {
      console.log("nothing is correct");
      setError1(true);
      setError2(true);
    } else if (product_name.length !== 0 && fullDescription.length === 0) {
      console.log("description is empty");
      setError2(true);
    } else if (product_name.length === 0 && fullDescription.length !== 0) {
      console.log("name is empty");
      setError1(true);
    } else {
      let edit = { product_name, fullDescription, shortDescription };
      console.log(edit);
      console.log(id);
      fetch(`https://api.allroundcare.org/admin/products?id=${id}`, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(edit),
      }).then((response) => {
        if (response.status !== 200) {
          console.log("not working");
          console.log(response);
          console.log(id);
        } else {
          console.log("its working");
          handleModalClose();
          window.location.reload();
        }
      });
    }
    //console.log(product_name)
    //console.log(fullDescription)
    //console.log(shortDescription)
  };

  return (
    <StyledModal
      open={viewProductDetailsModalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          width: { xs: "93vw", sm: "93vw", md: "40vw" },
          px: "44px",
          py: "30px",
          backgroundColor: "white",
          borderRadius: "10px",
          display: "block",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Edit Product Texts
          </Typography>
          <CloseIcon
            sx={{ cursor: "pointer" }}
            onClick={() => handleModalClose()}
          />
        </Box>
        <Box
          sx={{
            py: "26px",
            px: "32px",
            border: "1px solid #B1B1B1",
            borderRadius: "10px",
            mt: "24px",
            display: "block",
          }}
        >
          <Box
            sx={{
              m: "0px",
              p: "0px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: "16px", color: "#696F79", fontWeight: "500" }}
            >
              Product Name
            </Typography>
            <TextField
              required
              id="outlined-required"
              placeholder={details.product_name}
              value={product_name}
              onChange={(e) => handleChange(e)}
              sx={{ width: "100%", borderRadius: "8px", height: "44px" }}
            />
            {error1 && (
              <Typography
                sx={{ color: "red", marginTop: "10px", fontsize: "16px" }}
              >
                field cannot be empty
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              marginTop: "24px",
              p: "0px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: "16px", color: "#696F79", fontWeight: "500" }}
            >
              Description
            </Typography>
            <TextField
              sx={{ borderRadius: "8px", width: "100%" }}
              id="outlined-multiline-static"
              multiline
              type="text"
              rows={4}
              placeholder={details.fullDescription}
              value={fullDescription}
              onChange={(e) => handleDes(e)}
            />
            {error2 && (
              <Typography
                sx={{ color: "red", marginTop: "6px", fontsize: "16px" }}
              >
                field cannot be empty
              </Typography>
            )}
          </Box>
          <Button
            type="button"
            variant="contained"
            onClick={() => handleSub()}
            sx={{
              width: "100%",
              height: "44px",
              backgroundColor: "#28A745",
              color: "white",
              mt: "24px",
              borderRadius: "8px",
              alignItems: "center",
              "&:hover": { backgroundColor: "#44ab5c" },
            }}
          >
            Update Details
          </Button>
        </Box>
      </Box>
    </StyledModal>
  );
};

export default ProductDetailsModal;
