import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  styled,
  Typography,
  Button,
  Stack,
  Avatar,
  FormControl,
  FormGroup,
  IconButton,
  Paper,
  MenuItem,
  Select,
  OutlinedInput,
  TextField,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddIcon from "@mui/icons-material/Add";
import AddPriceModal from "./AddPriceModal";
import TagsModal from "./TagsModal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useDropzone } from "react-dropzone";
import Validate from "./Validate";
import LoadingModal from "./LoadingModal";
import ErrorModal from "./ErrorModal";

const schema = yup.object().shape({
  product_name: yup.string().required("product name is required"),
  categories: yup.array().required("caetgories is required"),
  fullDescription: yup.string().required("Full description is required"),
});

const ITEM_HEIGHT = 48;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
    },
  },
};

const AddProductsMain = () => {
  const [categories, setCategories] = React.useState([]); // im sending this
  const [product_name, setProduct_name] = useState(""); //im sending this
  const [categ, setCateg] = useState([]);
  const [fullDescription, setFullDescription] = useState(""); // im sending this
  const [prices, setPrices] = useState([]); // im sending this
  const [priceOpen, setPriceOpen] = useState(false);
  const [priceCheck, setPriceCheck] = useState(false);
  const [price_name, setPrice_name] = useState(""); //im sending this
  const [amount, setAmount] = useState("");
  const [size, setSize] = useState("");
  const [errorMessage1, setErrorMessage1] = useState(false);
  const [errorMessage2, setErrorMessage2] = useState(false);
  const [errorMessage3, setErrorMessage3] = useState(false);
  const [files, setFiles] = useState([]); // im sending ths
  const [tags, setTags] = useState([]); //im sending thhis
  const [tagValue, setTagValue] = useState("");
  const [uploadError, setUploadError] = useState(false);
  const [tagsCheck, setTagsCheck] = useState(false);
  const [tagsOpen, setTagsOpen] = useState(false);
  const [tagError, setTagError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [tagsError, setTagsError] = useState(false);
  const [imagesError, setImagesError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingModalOpen, setLoadingModalOpen] = useState(false);
  const [postError, setPostError] = useState("");
  const [postErrorCheck, setPostErrorCheck] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const userToken = localStorage.getItem("token");
  let formData = new FormData();
  const headers = new Headers();
  headers.append("Authorization", "Bearer" + " " + userToken);
  const submitForm = (data) => {
    const shortDescription = data.fullDescription.slice(0, 20);
    const slug = data.product_name.replace(/ +/g, "_");
    data.slug = slug;
    data.shortDescription = shortDescription;

    if (prices.length === 0 && tags.length === 0 && files.length === 0) {
      setImagesError(true);
      setTagsError(true);
      setPriceError(true);
    } else if (prices.length === 0 && tags.length !== 0 && files.length !== 0) {
      setPriceError(true);
    } else if (prices.length !== 0 && tags.length === 0 && files.length === 0) {
      setImagesError(true);
      setTagsError(true);
    } else if (prices.length !== 0 && tags.length === 0 && files.length !== 0) {
      setTagsError(true);
    } else if (prices.length === 0 && tags.length !== 0 && files.length === 0) {
      setImagesError(true);
      setPriceError(true);
    } else if (prices.length !== 0 && tags.length !== 0 && files.length === 0) {
      setImagesError(true);
    } else if (prices.length === 0 && tags.length === 0 && files.length !== 0) {
      setPriceError(true);
      setTagsError(true);
    } else {
      setLoading(true);
      setLoadingModalOpen(true);
      data.prices = prices;
      data.tags = tags;
      formData.append("body", JSON.stringify(data));
      for (let i = 0; i < files.length; i++) {
        formData.append("img" + (i + 1), files[i]);
      }
      console.log(formData.has("img"));
      console.log(formData.has("body"));
      console.log(formData.entries());
      console.log(formData);
      for (var pair of formData.entries()) {
        console.log(pair[0] + "," + pair[1]);
      }
      fetch("https://api.allroundcare.org/admin/products", {
        method: "POST",
        headers: headers,
        body: formData,
      })
        .then((res) => {
          if (res.status !== 200) {
            throw Error("failed to add new product");
            setLoading(false);
            setLoadingModalOpen(false);
            setPostErrorCheck(true);
            setErrorModalOpen(true);
          } else {
            setLoading(false);
            setLoadingModalOpen(false);
            navigate("/products");
          }
        })
        .catch((err) => {
          setPostError(err.message);
        });
    }
  };
  const priceObj = { price_name, amount, size };
  const navigate = useNavigate();
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length < 3) {
      setUploadError(true);
    } else if (acceptedFiles.length > 5) {
      setUploadError(true);
    } else {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setUploadError(false);
    }
  }, []);
  const maxFiles = 5;
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
    maxFiles: 5,
  });
  const tagsClose = () => {
    setTagsCheck(false);
    setTagsOpen(false);
  };
  const handleChanger = (event) => {
    const {
      target: { value },
    } = event;
    setCategories(
      typeof value === "string" ? value.split(",") : value
      // On autofill we get a stringified value.
    );
  };
  const errorModalClose = () => {
    setErrorModalOpen(false);
    setPostErrorCheck(false);
  };
  const productChange = (e) => {
    setProduct_name(e.target.value);
  };
  const priceClose = () => {
    setPriceOpen(false);
    setPriceCheck(false);
  };
  const openPrice = () => {
    setPriceOpen(true);
    setPriceCheck(true);
  };
  const desChange = (e) => {
    setFullDescription(e.target.value);
  };
  const priceNameChange = (e) => {
    setPrice_name(e.target.value);
  };
  const priceAmountChange = (e) => {
    setAmount(e.target.value);
  };
  const priceSizeChange = (e) => {
    setSize(e.target.value);
  };
  const handleBlur = () => {
    if (price_name.length === 0) {
      setErrorMessage1(true);
    } else {
      setErrorMessage1(false);
    }
  };
  const loadingModalClose = () => {
    setLoading(false);
    setLoadingModalOpen(false);
  };
  const handleBlur2 = () => {
    if (amount.length === 0) {
      setErrorMessage2(true);
    } else {
      setErrorMessage2(false);
    }
  };
  const handleBlur3 = () => {
    if (size.length === 0) {
      setErrorMessage3(true);
    } else {
      setErrorMessage3(false);
    }
  };
  const tagsModalOpen = () => {
    setTagsCheck(true);
    setTagsOpen(true);
  };
  const pushTag = () => {
    if (tagValue.length === 0) {
      setTagError(true);
    } else {
      tags.push(tagValue);
      setTagValue("");
      setTagsCheck(false);
      setTagsOpen(false);
      setTagError(false);
    }
  };
  const tagsDelete = (index) => {
    let i = index;
    setTags(tags.filter((e, index) => index !== i));
  };
  const pushPrice = () => {
    if (price_name.length === 0 && amount.length === 0 && size.length === 0) {
      console.log("all are empty");
      setErrorMessage1(true);
      setErrorMessage2(true);
      setErrorMessage3(true);
    } else if (
      amount.length === 0 &&
      size.length === 0 &&
      price_name.length !== 0
    ) {
      setErrorMessage2(true);
      setErrorMessage3(true);
    } else if (
      amount.length !== 0 &&
      size.length !== 0 &&
      price_name.length === 0
    ) {
      setErrorMessage1(true);
    } else if (
      price_name.length === 0 &&
      size.length === 0 &&
      amount.length !== 0
    ) {
      setErrorMessage1(true);
      setErrorMessage3(true);
    } else if (
      price_name.length !== 0 &&
      size.length !== 0 &&
      amount.length === 0
    ) {
      setErrorMessage2(true);
    } else if (
      price_name.length === 0 &&
      amount.length === 0 &&
      size.length !== 0
    ) {
      setErrorMessage1(true);
      setErrorMessage2(true);
    } else if (
      price_name.length !== 0 &&
      amount.length !== 0 &&
      size.length === 0
    ) {
      setErrorMessage3(true);
    } else {
      prices.push(priceObj);
      setPriceOpen(false);
      setPriceCheck(false);
      setPrice_name("");
      setAmount("");
      setSize("");
      setErrorMessage1(false);
      setErrorMessage2(false);
      setErrorMessage3(false);
    }
  };
  const tagNameChange = (e) => {
    setTagValue(e.target.value);
  };
  const handleTesting = (index) => {
    for (let i = 0; i < prices.length; i++) {
      if (index === i) {
        const newPrice = prices.filter((price, index) => index === prices[i]);
        setPrices(newPrice);
      }
    }
  };

  useEffect(() => {
    fetch("https://api.allroundcare.org/utilites/categories")
      .then((res) => {
        if (res.status !== 200) {
          throw Error("Failed to load categories");
        }
        return res.json();
      })
      .then((data) => {
        setCateg(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <Box
      sx={{
        width: { xs: "100%", sx: "100%", md: "82.64%" },
        backgroundColor: "none",
        px: "25px",
        paddingTop: "32px",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          width: "55%",
          display: "flex",
          flexDirection: "row",
          cursor: "pointer",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          onClick={() => navigate("/products")}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <ArrowBackIosIcon sx={{ color: "#28A745" }} />
          <Typography variant="p" sx={{ color: "#28A745" }}>
            back
          </Typography>
        </Box>
        <Typography variant="h5">Add Products</Typography>
      </Box>
      <Box sx={{ width: "100%", paddingBottom: "32px" }}>
        <form
          onSubmit={handleSubmit(submitForm)}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              marginTop: "34px",
              padding: "0px",
              display: "flex",
              flexDirection: { xs: "column", sm: "column", md: "row" },
              width: "100%",
              justifyContent: {
                xs: "flex-start",
                sm: "flex-start",
                md: "space-between",
              },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", sm: "100%", md: "48%" },
                display: "flex",
                flexDirection: "column",
                px: "20px",
                paddingTop: "20px",
                border: "1px solid #C7E7CE",
                borderRadius: "10px",
              }}
            >
              <Typography variant="h6">Product Images</Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "auto auto auto",
                  padding: "0px",
                  marginTop: "20px",
                  columnGap: "10px",
                  rowGap: "20px",
                }}
              >
                {files.map((file) => (
                  <Box
                    key={file.name}
                    sx={{
                      height: "200px",
                      width: "100%",
                      border: "2px dashed rgba(0,0,0,0.2)",
                      borderRadius: "10px",
                    }}
                  >
                    <img
                      src={file.preview}
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                      onLoad={() => {
                        URL.revokeObjectURL(file.preview);
                      }}
                    />
                  </Box>
                ))}
              </Box>
              <Paper
                sx={{
                  cursor: "pointer",
                  border: "2px dashed rgba(0,0,0,0.2)",
                  borderRadius: "10px",
                  marginTop: "32px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ padding: "16px" }} {...getRootProps()}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <Typography>Drop the files here.....</Typography>
                  ) : (
                    <Typography
                      sx={{ color: "rgba(0,0,0,0.5)", fontSize: "18px" }}
                    >
                      Click or drag Images
                    </Typography>
                  )}
                  <em style={{ color: "#069426" }}>
                    (Images with *jpeg, *png, *jpg)
                  </em>
                </div>
              </Paper>
              {uploadError && (
                <Typography
                  variant="p"
                  sx={{ color: "red", marginTop: "24px" }}
                >
                  Number of uploaded files should not be less than 3 or more
                  than 5
                </Typography>
              )}
              {imagesError && (
                <Typography
                  variant="p"
                  sx={{ color: "red", marginTop: "24px" }}
                >
                  Please add images
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                px: "20px",
                paddingTop: "20px",
                border: "1px solid #C7E7CE",
                borderRadius: "10px",
                width: { xs: "100%", sm: "100%", md: "48%" },
                marginTop: { xs: "24px", sm: "24px", md: "0px" },
              }}
            >
              <Typography variant="h6">Product Details</Typography>
              <Box sx={{ display: "block", mt: "20px" }}>
                <Typography sx={{ color: "rgba(0,0,0, 0.5)" }}>
                  Product Name
                </Typography>
                <TextField
                  type="text"
                  id="outlined-basic"
                  //value={product_name}
                  onChange={(e) => productChange(e)}
                  {...register("product_name")}
                  variant="outlined"
                  placeholder="Enter product name"
                  sx={{
                    border: "1px solid #A1A1A1",
                    borderRadius: "8px",
                    width: "100%",
                  }}
                />
                <Typography variant="p" sx={{ color: "red" }}>
                  {errors.product_name?.message}
                </Typography>
              </Box>
              <Box
                sx={{
                  p: "0",
                  marginTop: "20px",
                  ml: "0",
                  mb: "0",
                  mr: "0",
                  display: "block",
                }}
              >
                <Typography sx={{ color: "rgba(0,0,0, 0.5)" }}>
                  Category
                </Typography>
                <Select
                  sx={{
                    width: "100%",
                    border: "1px solid #A1A1A1",
                    borderRadius: "8px",
                  }}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={categories}
                  {...register("categories")}
                  onChange={handleChanger}
                  input={<OutlinedInput label="Name" />}
                  MenuProps={MenuProps}
                >
                  {categ.map((cater) => (
                    <MenuItem value={cater.name} key={cater.id}>
                      {cater.name}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="p" sx={{ color: "red" }}>
                  {errors.categories?.message}
                </Typography>
              </Box>
              <Box sx={{ display: "block", mt: "20px", p: "0" }}>
                <Typography sx={{ color: "rgba(0,0,0, 0.5)" }}>
                  Description
                </Typography>
                <TextField
                  sx={{
                    border: "1px solid #A1A1A1",
                    borderRadius: "8px",
                    width: "100%",
                  }}
                  id="outlined-multiline-static"
                  multiline
                  type="text"
                  rows={4}
                  //value={fullDescription}
                  onChange={(e) => desChange(e)}
                  {...register("fullDescription")}
                  placeholder="Write short description here"
                />
                <Typography variant="p" sx={{ color: "red" }}>
                  {errors.fullDescription?.message}
                </Typography>
              </Box>
              <Box
                sx={{
                  border: "2px dashed #A1A1A1",
                  paddingTop: "10px",
                  px: "10px",
                  borderRadius: "8px",
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography sx={{ color: "rgba(0,0,0, 0.5)" }}>
                    Price
                  </Typography>
                </Box>
                {prices.map((price, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: "100%",
                      borderBottom: "1px solid #A7DBB3",
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="p" sx={{ fontWeight: "500" }}>
                      {price.price_name}
                    </Typography>
                    <Typography variant="p" sx={{ color: "#069426" }}>
                      {price.amount}
                    </Typography>
                    <Box
                      onClick={() => handleTesting(index)}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        p: "0",
                        cursor: "pointer",
                      }}
                    >
                      <CloseIcon sx={{ color: "#FB0000" }} />
                      <Typography variant="p" sx={{ color: "#FB0000" }}>
                        Remove
                      </Typography>
                    </Box>
                  </Box>
                ))}
                <Button
                  variant="text"
                  type="button"
                  onClick={() => openPrice()}
                  startIcon={<AddIcon sx={{ color: "#069426" }} />}
                  sx={{
                    marginTop: "16px",
                    cursor: "pointer",
                    color: "#069426",
                  }}
                >
                  Add product Price
                </Button>
              </Box>
              {priceError && (
                <Typography variant="p" sx={{ color: "red", marginTop: "8px" }}>
                  Price cannot be empty
                </Typography>
              )}
              <Box
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography sx={{ color: "rgba(0,0,0, 0.5)" }}>
                    Tags
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginTop: "16px",
                    display: "grid",
                    gridTemplateColumns: "auto auto auto",
                    columnGap: "20px",
                    rowGap: "20px",
                    width: "100%",
                  }}
                >
                  {tags.map((tag, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "10px",
                        borderRadius: "20px",
                        backgroundColor: "#98fb98",
                      }}
                    >
                      <Typography variant="p" sx={{ fontSize: "16px" }}>
                        {tag}
                      </Typography>
                      <IconButton onClick={() => tagsDelete(index)}>
                        <HighlightOffIcon />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
                <Button
                  type="button"
                  startIcon={<AddIcon sx={{ color: "#069426" }} />}
                  sx={{
                    marginTop: "16px",
                    cursor: "pointer",
                    color: "#069426",
                  }}
                  onClick={() => tagsModalOpen()}
                >
                  Add Tags
                </Button>
              </Box>
              {tagsError && (
                <Typography variant="p" sx={{ color: "red", marginTop: "8px" }}>
                  Tags cannot be empty
                </Typography>
              )}
              {priceCheck && (
                <AddPriceModal
                  priceOpen={priceOpen}
                  priceClose={priceClose}
                  priceNameChange={priceNameChange}
                  priceAmountChange={priceAmountChange}
                  priceSizeChange={priceSizeChange}
                  pushPrice={pushPrice}
                  handleBlur={handleBlur}
                  errorMessage1={errorMessage1}
                  handleBlur2={handleBlur2}
                  errorMessage2={errorMessage2}
                  errorMessage3={errorMessage3}
                  handleBlur3={handleBlur3}
                  pushTag={pushTag}
                />
              )}
              {tagsCheck && (
                <TagsModal
                  tagsOpen={tagsOpen}
                  tagsClose={tagsClose}
                  tagValue={tagValue}
                  tagNameChange={tagNameChange}
                  pushTag={pushTag}
                  tagError={tagError}
                />
              )}
            </Box>
          </Box>
          <Button
            type="submit"
            variant="contained"
            sx={{
              width: "50%",
              marginTop: "24px",
              borderRadius: "10px",
              height: "48px",
              backgroundColor: "#28A745",
              "&:hover": { backgroundColor: "#44ab5c" },
            }}
          >
            Add Products
          </Button>
        </form>
      </Box>
      {loading && (
        <LoadingModal
          loadingModalClose={loadingModalClose}
          loadingModalOpen={loadingModalOpen}
        />
      )}
      {postErrorCheck && (
        <ErrorModal
          errorModalClose={errorModalClose}
          errorModalOpen={errorModalOpen}
          postError={postError}
        />
      )}
    </Box>
  );
};

export default AddProductsMain;
