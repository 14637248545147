import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  styled,
  Typography,
  Button,
  Stack,
  Avatar,
  FormControl,
  FormGroup,
  IconButton,
  Modal,
  Paper,
  MenuItem,
  Select,
  OutlinedInput,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import BeatLoader from "react-spinners/BeatLoader";

const StyledModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "60px",
  paddingBottom: "60px",
  overflow: "auto",
});
const ITEM_HEIGHT = 48;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
    },
  },
};
const schema = yup.object().shape({
  first_name: yup.string().required("first name is required"),
  last_name: yup.string().required("Last name is required"),
  phone: yup.number().positive().integer().required("phone number is required"),
  email: yup.string().email().required("Email is required"),
});

const CreateUserModal = ({
  createUserOpen,
  closeCreateUser,
  type,
  handleUserType,
  role,
}) => {
  const [erroe, setErroe] = useState(false);
  const [initial, setInitial] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const userToken = localStorage.getItem("token");
  const headers = new Headers();
  headers.append("Content-type", "application/json");
  headers.append("Authorization", "Bearer" + " " + userToken);
  const subb = (data) => {
    data.type = type.toLowerCase();
    data.role = role.toLowerCase();
    console.log(data);
    if (data.type.length === 0) {
      setErroe(true);
    } else {
      console.log(data);
      setInitial(false);
      fetch("https://api.allroundcare.org/admin/add_user", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      }).then((res) => {
        if (res.status !== 200) {
          console.log("not submitted");
          console.log(res.msg);
        } else {
          closeCreateUser();
          setInitial(true);
        }
      });
    }
  };

  return (
    <StyledModal
      open={createUserOpen}
      onClose={closeCreateUser}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          width: { xs: "90vw", sm: "90vw", md: "40vw" },
          maxHeight: "90vh",
          overflow: "auto",
          px: { xs: "20px", sm: "20px", md: "44px" },
          py: { xs: "20px", sm: "20px", md: "30px" },
          backgroundColor: "white",
          borderRadius: "10px",
          display: "block",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Add User</Typography>
          <CloseIcon
            sx={{ cursor: "pointer" }}
            onClick={() => closeCreateUser()}
          />
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit(subb)}
          sx={{ mt: "24px", display: "block" }}
        >
          <Box
            sx={{
              m: "0px",
              p: "0px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: "16px", color: "#696F79", fontWeight: "500" }}
            >
              First Name
            </Typography>
            <TextField
              required
              variant="outlined"
              type="text"
              placeholder="Enter first name"
              {...register("first_name")}
              sx={{
                width: "100%",
                borderRadius: "8px",
                height: "44px",
                mt: "3px",
              }}
            />
            <Typography variant="p" sx={{ color: "red", marginTop: "10px" }}>
              {errors.first_name?.message}
            </Typography>
          </Box>
          <Box
            sx={{
              mt: "24px",
              p: "0px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: "16px", color: "#696F79", fontWeight: "500" }}
            >
              Last Name
            </Typography>
            <TextField
              required
              variant="outlined"
              type="text"
              placeholder="Enter last name"
              {...register("last_name")}
              sx={{
                width: "100%",
                borderRadius: "8px",
                height: "44px",
                mt: "3px",
              }}
            />
            <Typography variant="p" sx={{ color: "red", marginTop: "10px" }}>
              {errors.last_name?.message}
            </Typography>
          </Box>
          <Box
            sx={{
              mt: "24px",
              p: "0px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: "16px", color: "#696F79", fontWeight: "500" }}
            >
              Phone number
            </Typography>
            <TextField
              required
              variant="outlined"
              type="number"
              pattern=".{11,11}"
              name="address"
              placeholder="0802-000-0000"
              {...register("phone")}
              sx={{
                width: "100%",
                borderRadius: "8px",
                height: "44px",
                mt: "3px",
              }}
            />
            <Typography variant="p" sx={{ color: "red", marginTop: "10px" }}>
              {errors.phone?.message}
            </Typography>
          </Box>
          <Box
            sx={{
              mt: "24px",
              p: "0px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: "16px", color: "#696F79", fontWeight: "500" }}
            >
              Email Address
            </Typography>
            <TextField
              required
              variant="outlined"
              type="email"
              placeholder="Enter your email"
              {...register("email")}
              sx={{
                width: "100%",
                borderRadius: "8px",
                height: "44px",
                mt: "3px",
              }}
            />
            <Typography variant="p" sx={{ color: "red", marginTop: "10px" }}>
              {errors.email?.message}
            </Typography>
          </Box>
          <Box
            sx={{
              p: "0",
              marginTop: "24px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: "16px", color: "#696F79", fontWeight: "500" }}
            >
              User Type
            </Typography>
            <Select
              sx={{
                width: "100%",
                borderRadius: "8px",
                height: "54px",
                mt: "3px",
              }}
              labelId="demo-multiple-name-label"
              placeholder="Select User Type"
              id="demo-multiple-name"
              value={type}
              onChange={(e) => handleUserType(e)}
              input={<OutlinedInput label="User type" />}
              MenuProps={MenuProps}
            >
              <MenuItem value={"shopper"}>shopper</MenuItem>
              <MenuItem value={"setailer"}>retailer</MenuItem>
            </Select>
            {erroe && (
              <Typography variant="p" sx={{ color: "red", marginTop: "10px" }}>
                User type is required
              </Typography>
            )}
          </Box>
          {initial === true ? (
            <Button
              variant="contained"
              type="submit"
              sx={{
                width: "100%",
                height: "44px",
                backgroundColor: "#28A745",
                color: "white",
                mt: "24px",
                borderRadius: "8px",
                alignItems: "center",
                "&:hover": { backgroundColor: "#44ab5c" },
              }}
            >
              Create New User
            </Button>
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: "0px",
                mt: "24px",
              }}
            >
              <BeatLoader color="#28A745" size={20} />
            </Box>
          )}
        </Box>
      </Box>
    </StyledModal>
  );
};

export default CreateUserModal;
