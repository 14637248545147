import React, {useState, useEffect, useCallback, useRef} from "react";
import {Modal, styled, Box, Typography, modalClasses, Stack, Button} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { ErrorMessage } from "formik";



const StyledModal = styled(Modal)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    //paddingTop:"150px",
    //paddingBottom:"60px",
    overflow:"auto"
});

const AddPriceModal = ({ priceOpen, priceClose, price_name, amount, size, priceNameChange, priceAmountChange, priceSizeChange, pushPrice, errors, handleBlur, errorMessage1, handleBlur2, errorMessage2, errorMessage3, handleBlur3}) => {


    return ( 
        <StyledModal 
        open={priceOpen}
        onClose={priceClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box sx={{width:{xs: "93vw", sm: "93vw", md:"40vw"},px:'44px', py:'30px', backgroundColor:'white', borderRadius:'10px', display:'block'}}>
              <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                <Typography variant='h5' sx={{fontWeight:'bold'}}>Add Price</Typography>
                <CloseIcon sx={{cursor:'pointer'}} onClick={()=>priceClose()}/>
              </Box>
              <Box sx={{py:'26px', px:'32px', border:'1px solid #B1B1B1', borderRadius:'10px', mt:'24px', display:'block'}}>
                <Box sx={{m:'0px', p:'0px', display:'flex', flexDirection:'column'}}>
                    <Typography variant="p" sx={{fontSize:'16px', color:'#696F79', fontWeight:'500'}}>Price Name</Typography>
                    <TextField
                    required
                    variant="outlined"
                    name="priceName"
                    placeholder='Enter price name'
                    value={price_name}
                    onChange={(e)=>priceNameChange(e)}
                    onBlur={handleBlur}
                    sx={{width:'100%', borderRadius:'8px', height:'44px',mt:'5px'}}
                    />
                    {errorMessage1 && <Typography sx={{marginTop: '10px', fontSize:'15px', color:'red'}}>Price name cannot be empty</Typography>}
                </Box>
                <Box sx={{marginTop:'24px', p:'0px', display:'flex', flexDirection:'column'}}>
                    <Typography variant="p" sx={{fontSize:'16px', color:'#696F79', fontWeight:'500'}}>Amount</Typography>
                    <TextField
                    required
                    variant="outlined"
                    name="priceAmount"
                    type="number"
                    placeholder='Enter Amount'
                    value={amount}
                    onChange={(e)=>priceAmountChange(e)}
                    onBlur={handleBlur2}
                    sx={{width:'100%', borderRadius:'8px', height:'44px',mt:'5px'}}
                    />
                    {errorMessage2 && <Typography sx={{marginTop: '10px', fontSize:'15px', color:'red'}}>Price amount cannot be empty</Typography>}
                </Box>
                <Box sx={{marginTop:'24px', p:'0px', display:'flex', flexDirection:'column'}}>
                    <Typography variant="p" sx={{fontSize:'16px', color:'#696F79', fontWeight:'500'}}>Size</Typography>
                    <TextField
                    required
                    variant="outlined"
                    type="number"
                    name="priceSize"
                    placeholder='Enter size'
                    value={size}
                    onChange={(e)=>priceSizeChange(e)}
                    onBlur={handleBlur3}
                    sx={{width:'100%', borderRadius:'8px', height:'44px',mt:'5px'}}
                    />
                </Box>
                {errorMessage3 && <Typography sx={{marginTop: '10px', fontSize:'15px', color:'red'}}>Price Size cannot be empty</Typography>}
                <Button
                onClick={()=>pushPrice()}
                variant="contained"
                sx={{width:'100%',height:'44px', backgroundColor:'#28A745', color:'white', mt:'24px', borderRadius:'8px', alignItems:'center', '&:hover': {backgroundColor:'#44ab5c'}}}>
                    Save
                </Button>
              </Box>
           </Box>
        </StyledModal>
    );
}
 
export default AddPriceModal;