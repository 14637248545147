import React, { useState, useEffect } from "react";
import { GiNetworkBars } from "react-icons/gi";
import { HiShoppingBag } from "react-icons/hi";
import { ImDropbox, ImCart } from "react-icons/im";
import { BsFillBasket2Fill } from "react-icons/bs";
import { RiEBikeFill } from "react-icons/ri";
import { FaClipboard } from "react-icons/fa";

import { Box } from "@mui/material";
import Card from "./card/Card";
import ReportChart from "./ReportChart";
import Chart from "./Chart/Chart";
import OrderedProduct from "./ordered_product/OrderedProduct";

const token = localStorage.getItem("token");

const ReportsMain = () => {
  const [totalRetailer, setTotalRetailer] = useState([]);
  const [totalShopper, setTotalShopper] = useState([]);
  const [totalProducts, setTotalProducts] = useState([]);
  const [totalOrders, setTotalOrders] = useState([]);

  useEffect(() => {
    fetch("https://api.allroundcare.org/users/retialers")
      .then((res) => {
        if (res.status !== 200) {
          throw Error("Unable to fetch data from resources");
        }
        return res.json();
      })
      .then((data) => {
        setTotalRetailer(data.body);
      })
      .catch((err) => {
        console.log(err);
      });

    fetch("https://api.allroundcare.org/users/shoppers")
      .then((res) => {
        if (res.status !== 200) {
          throw Error("unable to fetch");
        }
        return res.json();
      })
      .then((data) => {
        setTotalShopper(data.body);
      })
      .catch((err) => {
        console.log(err);
      });

    fetch("https://api.allroundcare.org/utilites/unlimited_products")
      .then((res) => {
        if (res.status !== 200) {
          throw Error("unable to fetch data from resources");
        }
        return res.json();
      })
      .then((data) => {
        setTotalProducts(data);
      })
      .catch((err) => {
        console.log(err);
      });

    try {
      fetch("http://api.allroundcare.org/admin/all_retailer_order", {
        method: "GET",
        headers: {
          Authorization: "Bearer" + " " + token,
        },
      })
        .then((res) => {
          if (res.status !== 200) {
            throw Error("Unable to fetch data from resource");
          }
          return res.json();
        })
        .then((data) => {
          let allOrder = [];
          if (data.length > 0) {
            allOrder = data.map((allElements) => {
              return {
                ...allElements,
                date: allElements.createdAt.slice(0, 10),
              };
            });
            console.log(allOrder);
            setTotalOrders(allOrder);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "82.64%",
          height: "50vh",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
          }}
        >
          <Card
            title={"Total Revenue"}
            amount={"N120, 000"}
            icon={<GiNetworkBars />}
          />
          <Card
            title={"Total Order"}
            amount={totalOrders.length}
            icon={<HiShoppingBag />}
          />
          <Card
            title={"Total Product"}
            amount={totalProducts.length}
            icon={<ImDropbox />}
          />
          <Card
            title={"Total Retailers"}
            amount={totalRetailer.length}
            icon={<BsFillBasket2Fill />}
          />
          <Card
            title={"Total Shoppers"}
            amount={totalShopper.length}
            icon={<ImCart />}
          />
          <Card
            title={"Total Dispatch Riders"}
            amount={"120"}
            icon={<RiEBikeFill />}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <ReportChart />
          <div
            style={{
              backgroundColor: "#c7e7ce",
              overflow: "scroll",
              width: "30%",
              borderRadius: "10px",
              height: "55vh",
              marginRight: "10px",
              padding: "10px",
            }}
          >
            <div
              style={{
                fontSize: "14px",
              }}
            >
              <p
                style={{
                  fontSize: "19px",
                  fontWeight: "600",
                }}
              >
                Highest Order in a month
              </p>
              <span>
                <p
                  style={{
                    color: "#28a745",
                    fontSize: "19px",
                    fontWeight: "600",
                  }}
                >
                  <FaClipboard /> 120
                </p>
              </span>
              <p
                style={{
                  fontSize: "19px",
                  fontWeight: "600",
                }}
              >
                Highest Order month
              </p>
              <span>
                <p
                  style={{
                    color: "#28a745",
                    fontSize: "19px",
                    fontWeight: "600",
                  }}
                >
                  July
                </p>
              </span>
              <p
                style={{
                  fontSize: "19px",
                  fontWeight: "600",
                }}
              >
                Lowest Order month
              </p>
              <span>
                <p
                  style={{
                    color: "#28a745",
                    fontSize: "19px",
                    fontWeight: "600",
                  }}
                >
                  <FaClipboard /> 12
                </p>
              </span>
              <p
                style={{
                  fontSize: "19px",
                  fontWeight: "600",
                }}
              >
                Lowest Order month
              </p>
              <span>
                <p
                  style={{
                    color: "#28a745",
                    fontSize: "19px",
                    fontWeight: "600",
                  }}
                >
                  January
                </p>
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "13px",
          }}
        >
          <Chart />
          <OrderedProduct />
        </div>
      </Box>
    </>
  );
};

export default ReportsMain;
