import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ViewUnassignedDetails from "./ViewUnassignedDetails";
import AssignShoppertoRet from "./AssignShoppertoRet";
import { getDistance } from "geolib";
import ViewRecipt from "./ViewRecipt";

const ITEM_HEIGHT = 48;

const ReceiptSubmitted = ({ receiptSubmited, retailers }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [unassignOrder, setUnassignOrder] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [isOpenTrue, setIsOpenTrue] = useState(false);
  const [totalCosts, setTotalCosts] = useState();
  const [isAssignTrue, setIsAssignTrue] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [receiptItem, setReceiptItem] = useState({});
  const [value, setValue] = useState("");

  const userToken = localStorage.getItem("token");

  const handleChaned = (event) => {
    setValue(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClick = (events, id, unassigned) => {
    setAnchorEl(events.currentTarget);
    let newUnassigned = {};
    newUnassigned.retailer_id = unassigned.retailer_id;
    newUnassigned.retailer_address = unassigned.retailer_address;
    newUnassigned.retailer_location = unassigned.retailer_location;
    newUnassigned.products = unassigned.products;
    newUnassigned.createdAt = unassigned.createdAt;
    newUnassigned._id = unassigned._id;
    retailers.map((ret) => {
      if (ret.id === unassigned.retailer_id) {
        newUnassigned.name = ret.first_name + " " + ret.last_name;
        newUnassigned.phone = ret.phone;
      }
    });
    setUnassignOrder(newUnassigned);
  };

  useEffect(() => {}, []);

  //   console.log(unassignOrder);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReceipt = () => {
    setIsAssignTrue(true);
    setAssignModal(true);
    const headers = new Headers();
    headers.append("Authorization", "Bearer" + " " + userToken);
    fetch(
      `https://api.allroundcare.org/admin/reciept?id=${unassignOrder._id}`,
      {
        method: "GET",
        headers: headers,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setReceiptItem(data);
      })
      .catch((err) => console.log(err));
  };

  const assignClose = () => {
    setIsAssignTrue(false);
    setAssignModal(false);
  };

  let coste = 0;

  const handleUnassignedDetatils = () => {
    setIsOpenTrue(true);
    setModalOpen(true);
    for (let i = 0; i < unassignOrder.products.length; i++) {
      let quantity = unassignOrder.products[i].prices[0].quantity;
      let amount = unassignOrder.products[i].prices[0].amount;
      coste += quantity * amount;
      setTotalCosts(coste);
    }
  };

  const modalClose = () => {
    setModalOpen(false);
    setIsOpenTrue(false);
  };

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#28A745" }}>
              <TableCell sx={{ backgroundColor: "transparent", color: "#fff" }}>
                Retaiiler Name
              </TableCell>
              <TableCell sx={{ backgroundColor: "transparent", color: "#fff" }}>
                Phone
              </TableCell>
              <TableCell sx={{ backgroundColor: "transparent", color: "#fff" }}>
                Total Products
              </TableCell>
              <TableCell sx={{ backgroundColor: "transparent", color: "#fff" }}>
                Order Date
              </TableCell>
              <TableCell sx={{ backgroundColor: "transparent", color: "#fff" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "#C7E7CE" }}>
            {receiptSubmited
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((unassigned) => (
                <TableRow key={unassigned.id}>
                  <TableCell>
                    {retailers.map((ret) => (
                      <Typography>
                        {ret.id === unassigned.retailer_id
                          ? ret.first_name + " " + ret.last_name
                          : null}
                      </Typography>
                    ))}
                  </TableCell>
                  <TableCell>
                    {retailers.map((ret) => (
                      <Typography>
                        {ret.id === unassigned.retailer_id ? ret.phone : null}
                      </Typography>
                    ))}
                  </TableCell>
                  <TableCell>{unassigned.products.length}</TableCell>
                  <TableCell>{unassigned.createdAt.slice(0, 10)}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={(events) =>
                        handleClick(events, unassigned._id, unassigned)
                      }
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: "20ch",
                          boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
                        },
                      }}
                    >
                      <MenuItem
                        sx={{ borderBottom: "1px solid #28A745" }}
                        onClick={() => handleUnassignedDetatils()}
                      >
                        View details
                      </MenuItem>
                      <MenuItem onClick={() => handleReceipt()}>
                        View Receipt
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isOpenTrue && (
        <ViewUnassignedDetails
          modalClose={modalClose}
          modalOpen={modalOpen}
          unassignOrder={unassignOrder}
          totalCosts={totalCosts}
        />
      )}
      {isAssignTrue && (
        <ViewRecipt
          assignModal={assignModal}
          assignClose={assignClose}
          handleChaned={handleChaned}
          receiptItem={receiptItem}
          unassignOrder={unassignOrder}
        />
      )}
      <TablePagination
        rowsPerPageOptions={[10, 20, 30, 40]}
        component="div"
        count={receiptSubmited.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default ReceiptSubmitted;
