import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";

function RideShareOrder({ rideSharePackages }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#28A745" }}>
              <TableCell sx={{ backgroundColor: "transparent", color: "#fff" }}>
                Order ID
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "transparent",
                  color: "#fff",
                  borderLeft: "1px solid white",
                }}
              >
                Order Type
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "transparent",
                  color: "#fff",
                  borderLeft: "1px solid white",
                }}
              >
                Order Status
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "transparent",
                  color: "#fff",
                  borderLeft: "1px solid white",
                }}
              >
                Order Date
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "transparent",
                  color: "#fff",
                  borderLeft: "1px solid white",
                }}
              >
                Driver ID
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rideSharePackages
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((rideSharePackage) => (
                <TableRow key={rideSharePackage.id}>
                  <TableCell>{rideSharePackage.id}</TableCell>
                  <TableCell
                    sx={{
                      borderLeft: "2px solid green",
                    }}
                  >
                    {rideSharePackage.delivery_type}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderLeft: "2px solid green",
                    }}
                  >
                    {rideSharePackage.status}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderLeft: "2px solid green",
                    }}
                  >
                    {rideSharePackage.createdAt.slice(0, 10)}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderLeft: "2px solid green",
                    }}
                  >
                    {rideSharePackage.driver_id || "No driver assigned"}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30, 40]}
        component="div"
        count={rideSharePackages.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default RideShareOrder;
