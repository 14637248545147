import React, {useState, useEffect, useCallback, useRef} from "react";
import {Modal, styled, Box, Typography, modalClasses, Stack, Button} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';

const StyledModal = styled(Modal)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    //paddingTop:"150px",
    //paddingBottom:"60px",
    overflow:"auto"
});

const TagsModal = ({tagsOpen,tagsClose, tagValue, tagNameChange, pushTag, tagError}) => {
  return (
    <StyledModal 
        open={tagsOpen}
        onClose={tagsClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box sx={{width:{xs: "93vw", sm: "93vw", md:"40vw"},px:'44px', py:'30px', backgroundColor:'white', borderRadius:'10px', display:'block'}}>
               <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                  <Typography variant='h5' sx={{fontWeight:'bold'}}>Add Tags</Typography>
                  <CloseIcon sx={{cursor:'pointer'}} onClick={()=>tagsClose()}/>
              </Box>
              <Box sx={{py:'26px', px:'32px', border:'1px solid #B1B1B1', borderRadius:'10px', mt:'24px', display:'block'}}>
              <Box sx={{m:'0px', p:'0px', display:'flex', flexDirection:'column'}}>
                <Typography variant="p" sx={{fontSize:'16px', color:'#696F79', fontWeight:'500'}}>Tag Name</Typography>
                <TextField
                required
                variant="outlined"
                name="tags"
                placeholder='Enter tag name'
                value={tagValue}
                onChange={(e)=>tagNameChange(e)}
                //onBlur={handleBlur}
                sx={{width:'100%', borderRadius:'8px', height:'44px',mt:'5px'}}
                />
                </Box>
                {tagError && <Typography sx={{color:'red', fontSize:'16px', marginTop:'16px'}}>field cannot be empty</Typography>}
                <Button
                onClick={()=>pushTag()}
                variant="contained"
                sx={{width:'100%',height:'44px', backgroundColor:'#28A745', color:'white', mt:'28px', borderRadius:'8px', alignItems:'center', '&:hover': {backgroundColor:'#44ab5c'}}}>
                    Add Tag
                </Button>
              </Box>
            </Box>
    </StyledModal>
  )
}

export default TagsModal
