import React from 'react';
import './card.css';

export default function Card({ title, icon, amount }) {
  return (
    <div className="cardContainer">
      <div className="cardHead">
        <h2>{title}</h2>
      </div>
      <div className="cardDetails">
        <h1>
          <span>
            {icon} {amount}
          </span>
        </h1>

        <div className="cardImage">{icon}</div>
      </div>
    </div>
  );
}
