import React from 'react';
import { Box, Stack } from '@mui/material';
import Navbar from './Sub-Components/Navbar';
import Sidebar4 from './Sub-Components/SideBar4';
import ReportsMain from './Sub-Components/ReportsMain';

const Reports = () => {
  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '0px',
          marginTop: '80px',
          justifyContent: 'center',
        }}
      >
        <Sidebar4 />
        <ReportsMain />
      </Box>
    </Box>
  );
};

export default Reports;
