import React from 'react';
import OrderSearchBar from './OrderSearchBar';
import { Box, Button, Modal } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const TopBar2 = ({ handleAddOrder }) => {
  return (
    <Box
      component="div"
      sx={{
        backgroundColor: '#C7E7CE',
        borderRadius: '10px',
        px: '16px',
        py: '25px',
        display: { sm: 'block', md: 'flex' },
        flexDirection: 'row',
        justifyContent: { sm: 'center', md: 'space-between' },
        alignItems: 'center',
      }}
    >
      <OrderSearchBar />
      <Button
        variant="contained"
        onClick={handleAddOrder}
        startIcon={<AddCircleIcon />}
        sx={{
          width: '177px',
          marginTop: '3px',
          marginLeft: '5px',
          height: '50px',
          backgroundColor: '#28A745',
          borderRadius: '10px',
          '&:hover': { backgroundColor: '#44ab5c' },
        }}
      >
        Add Orders
      </Button>
    </Box>
  );
};

export default TopBar2;
