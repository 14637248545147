import React from 'react'

const Validate = (priceObj) => {

  let errors ={};

  if(!priceObj.priceName){
    errors.priceName = "Price name is required "
  }
  if(!priceObj.priceAmounts){
    errors.priceAmounts ="price amount is required"
  }
  if (!priceObj.priceSize){
    errors.priceSize = "price size is required"
  }
  return errors;
}

export default Validate

