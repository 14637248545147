import React from 'react';
import {
  Box,
  styled,
  Typography,
  Modal,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StyledModal = styled(Modal)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '60px',
  paddingBottom: '60px',
  overflow: 'auto',
});

const ViewUnassignedDetails = ({
  modalOpen,
  modalClose,
  unassignOrder,
  totalCosts,
}) => {
  return (
    <StyledModal
      open={modalOpen}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          width: { xs: '90vw', sm: '90vw', md: '40vw' },
          maxHeight: '90vh',
          overflow: 'auto',
          px: { xs: '20px', sm: '20px', md: '44px' },
          py: { xs: '20px', sm: '20px', md: '30px' },
          backgroundColor: 'white',
          borderRadius: '10px',
          display: 'block',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5">Order Details</Typography>
          <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => modalClose()} />
        </Box>
        <Box sx={{ width: '100%', padding: '0px', marginTop: '16px' }}>
          <Box sx={{ display: 'block' }}>
            <Typography sx={{ fontWeight: '500', color: '#28A745' }}>
              Retailer Name
            </Typography>
            <Typography>{unassignOrder.name}</Typography>
          </Box>
          <Box sx={{ display: 'block' }}>
            <Typography
              sx={{ fontWeight: '500', color: '#28A745', marginTop: '16px' }}
            >
              Retailer Phone
            </Typography>
            <Typography>{unassignOrder.phone}</Typography>
          </Box>
          <Box sx={{ display: 'block' }}>
            <Typography
              sx={{ fontWeight: '500', color: '#28A745', marginTop: '16px' }}
            >
              Retailer Address
            </Typography>
            <Typography>{unassignOrder.retailer_address}</Typography>
          </Box>
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#28A745' }}>
                    <TableCell
                      sx={{ backgroundColor: 'transparent', color: '#fff' }}
                    >
                      Product Name
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: 'transparent', color: '#fff' }}
                    >
                      Quantity
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: 'transparent', color: '#fff' }}
                    >
                      Unit Cost
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: 'transparent', color: '#fff' }}
                    >
                      Total Cost
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: '#C7E7CE' }}>
                  {unassignOrder.products.map((pro) => (
                    <TableRow key={pro._id}>
                      <TableCell>{pro.product_name}</TableCell>
                      <TableCell>{pro.prices[0].quantity}</TableCell>
                      <TableCell>{pro.prices[0].amount}</TableCell>
                      <TableCell>
                        {pro.prices[0].quantity * pro.prices[0].amount}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography>Total Cost</Typography>
              <Typography sx={{ color: '#28A745', fontWeight: 'bold' }}>
                {totalCosts}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </StyledModal>
  );
};

export default ViewUnassignedDetails;
