import {
  Box,
  AppBar,
  Toolbar,
  styled,
  Typography,
  Button,
  Stack,
  Avatar,
  FormControl,
  FormGroup,
  IconButton,
  Paper,
  MenuItem,
  Select,
  OutlinedInput,
  TextField,
} from "@mui/material";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TopBarOrder from "./TopBarOrder";
import Checkbox from "@mui/material/Checkbox";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CheckOutModal from "./CheckOutModal";
import AddRetailer from "./AddRetailer";
import AddRetAdd from "./AddRetAdd";
import AddShopper from "./AddShopper";
import { getDistance } from "geolib";

const AddOrderMain = () => {
  const [products, setProducts] = useState([]);
  const [checked, setChecked] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [prod, setProd] = useState([]); // Im sending this
  const [checkOutOpen, setCheckOutOpen] = useState(false);
  const [checkOpen, setCheckOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState("");
  const [addEmpty, setAddEmpty] = useState(false);
  const [totalCosts, setTotalCosts] = useState();
  const [addRet, setAddRet] = useState(false);
  const [addRetOpen, setAddRetOpen] = useState(false);
  const [retailers, setRetailers] = useState([]);
  const [retailerd, setRetailerd] = useState("");
  const [retailer_id, setRetailer_id] = useState();
  const [retailer_address, setRetailer_address] = useState(""); // Im sending this
  const [initial, setInitial] = useState(true);
  const [error, setError] = useState(false);
  const [longitude, setLongitude] = useState();
  const [latitude, setLatitude] = useState();
  const [addNewRetailer, setAddNewRetailer] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [init, setInit] = useState(true);
  const [shoppers, setShoppers] = useState([]);
  const [addShopperOpen, setAddShopperOpen] = useState(false);
  const [addShopperCheck, setAddShopperCheck] = useState(false);
  const [searcher, setSearcher] = useState("");
  const [value, setValue] = useState("");
  const [ini, setIni] = useState(true);
  const [shopper_location, setShopper_location] = useState({});
  const [shopper_id, setShopper_id] = useState();
  const [distance, setDistance] = useState();

  const handleChangeed = (event) => {
    setValue(event.target.value);
  };

  const handleClick = (e, id, product) => {
    let productIndex = products.findIndex((x) => x._id === id);
    let currentCheckedStatus = products[productIndex].isChecked;
    let updatedProducts = [...products];
    updatedProducts[productIndex].isChecked = !currentCheckedStatus;
    setProducts(updatedProducts);
  };

  const handleAdd = (id, product) => {
    let proIndex = products.findIndex((pro) => pro._id === id);
    let quan = products[proIndex].quantity;
    let updatedQuantity = quan + 1;
    let pro = [...products];
    pro[proIndex].quantity = updatedQuantity;
    setProducts(pro);
  };

  const handleSub = (id, product) => {
    let proIndex = products.findIndex((pro) => pro._id === id);
    let quan = products[proIndex].quantity;
    let updatedQuantity = quan - 1;
    if (updatedQuantity < 0) {
      return;
    }
    let pro = [...products];
    pro[proIndex].quantity = updatedQuantity;
    setProducts(pro);
  };
  let coste = 0;

  const handleCheckout = () => {
    const productsToBuy = products.filter((x) => x.isChecked && x.quantity > 0);
    const mappedProductsToBuy = productsToBuy.map((eachProduct) => {
      const { product_name, product_images, quantity } = eachProduct;
      const { amount, price_name, _id } = eachProduct.prices[0];
      return {
        _id: eachProduct._id,
        product_name,
        product_image: product_images[0].src,
        prices: [{ _id, amount, price_name, quantity }],
      };
    });
    if (mappedProductsToBuy.length !== 0) {
      setCheckOpen(true);
      setCheckOutOpen(true);
      setAddEmpty(false);
    } else {
      setAddEmpty(true);
    }
    setProd(mappedProductsToBuy);
    //console.log(prod)
    for (let i = 0; i < mappedProductsToBuy.length; i++) {
      let quantity = mappedProductsToBuy[i].prices[0].quantity;
      let amount = mappedProductsToBuy[i].prices[0].amount;
      coste += quantity * amount;
      setTotalCosts(coste);
    }
  };

  const checkOutClose = () => {
    setCheckOpen(false);
    setCheckOutOpen(false);
  };
  const handleChanger = (value) => {
    setSearch(value);
  };
  const handleChangedd = (value) => {
    setSearched(value);
  };
  const handleNext = () => {
    setCheckOpen(false);
    setAddRet(true);
    setAddRetOpen(true);
    setCheckOutOpen(false);
  };
  const addRetClose = () => {
    setAddRet(false);
    setAddRetOpen(false);
    setInitial(true);
  };
  const handleChangerd = (event) => {
    setRetailerd(event.target.value);
  };
  const catchId = (id) => {
    setRetailer_id(id);
  };
  const addressChange = (e) => {
    setRetailer_address(e.target.value);
  };
  const addNewRet = () => {
    setAddRet(false);
    setAddRetOpen(false);
    setOpenAdd(true);
    setAddNewRetailer(true);
  };
  const addRetNewClose = () => {
    setOpenAdd(false);
    setAddNewRetailer(false);
  };
  const closeAddShopper = () => {
    setAddShopperCheck(false);
    setAddShopperOpen(false);
  };
  const handleChanget = (value) => {
    setSearcher(value);
  };
  const handleLet = (id, shopper) => {
    let lat = parseFloat(shopper.shopper.last_latitude);
    let lon = parseFloat(shopper.shopper.last_longitude);
    setShopper_location({ latitude: lat, longitude: lon });
    console.log(id);
    setShopper_id(id);
    console.log(shopper.distance);
    setDistance(shopper.distance);
  };
  const handleNex = () => {
    if (retailerd.length === 0 && retailer_address.length === 0) {
      console.log("cannot be empty");
    } else if (retailerd.length === 0 && retailer_address.length !== 0) {
      console.log("retailer cannot be empty");
    } else if (retailerd.length !== 0 && retailer_address.length === 0) {
      console.log("address cannot be empty");
    } else {
      //console.log({retailer_id, retailer_address, prod})
      setInitial(false);
      let data = {};
      data.retailer_id = retailer_id;
      data.retailer_address = retailer_address;
      data.products = prod;
      //let url = "https://nominatim.openstreetmap.org/search?format=json&limit=1&q="+retailer_address
      fetch(
        `https://api.geoapify.com/v1/geocode/search?text=${retailer_address}&limit=1&type=street&format=json&apiKey=8291c89c82404a2c9a1fc2c2cf426446`
      )
        .then((res) => {
          if (res.status !== 200) {
            throw Error("failed to fetch");
          }
          return res.json();
        })
        .then((data) => {
          if (data.results.length === 0) {
            setInitial(true);
            setError(true);
          } else {
            fetch("https://api.allroundcare.org/admin/all_online_shoppers", {
              method: "GET",
              headers: {
                Authorization: "Bearer" + " " + userToken,
              },
            })
              .then((res) => {
                if (res.status !== 200) {
                  throw Error("Failed to load products");
                }
                return res.json();
              })
              .then((data1) => {
                console.log(data1);
                let newData = [];
                if (data1.body.length > 0) {
                  newData = data1.body.map((allElements) => {
                    return {
                      ...allElements,
                      distance:
                        getDistance(
                          {
                            latitude: allElements.shopper.last_latitude,
                            longitude: allElements.shopper.last_longitude,
                          },
                          {
                            latitude: data.results[0].lat,
                            longitude: data.results[0].lon,
                          }
                        ) / 1000,
                    };
                  });
                }
                setShoppers(newData);
              });
            setError(false);
            setLongitude(data.results[0].lon);
            setLatitude(data.results[0].lat);
            addRetClose();
            setAddShopperCheck(true);
            setAddShopperOpen(true);
          }
        });
      let retailer_location = { longitude, latitude };
      data.retailer_location = retailer_location;
    }
  };

  const userToken = localStorage.getItem("token");
  const headers = new Headers();
  headers.append("Content-type", "application/json");
  headers.append("Authorization", "Bearer" + " " + userToken);

  const subb = (data) => {
    setInit(false);
    let address = data.address;
    let type = "retailer";
    let role = type;
    let user = {};
    user.email = data.email;
    user.phone = data.phone;
    user.first_name = data.first_name;
    user.last_name = data.last_name;
    user.type = type;
    user.role = role;

    fetch("https://api.allroundcare.org/admin/add_user", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(user),
    })
      .then((res) => {
        if (res.status !== 200) {
          throw Error("Unable to fetch");
        }
        return res.json();
      })
      .then((data) => {
        setRetailer_id(data.data.id);
        fetch(
          `https://api.geoapify.com/v1/geocode/search?text=${address}&limit=1&type=street&format=json&apiKey=8291c89c82404a2c9a1fc2c2cf426446`
        )
          .then((res) => {
            if (res.status !== 200) {
              throw Error("Unable to get");
            }
            return res.json();
          })
          .then((data1) => {
            if (data1.results.length === 0) {
              console.log("Please Enter a valid address");
            } else {
              fetch("https://api.allroundcare.org/admin/all_online_shoppers", {
                method: "GET",
                headers: {
                  Authorization: "Bearer" + " " + userToken,
                },
              })
                .then((res) => {
                  if (res.status !== 200) {
                    throw Error("Failed to load shopper");
                  }
                  return res.json();
                })
                .then((dat) => {
                  console.log(dat);
                  let newData = [];
                  if (dat.body.length > 0) {
                    newData = dat.body.map((allElements) => {
                      return {
                        ...allElements,
                        distance:
                          getDistance(
                            {
                              latitude: allElements.shopper.last_latitude,
                              longitude: allElements.shopper.last_longitude,
                            },
                            {
                              latitude: data1.results[0].lat,
                              longitude: data1.results[0].lon,
                            }
                          ) / 1000,
                      };
                    });
                  }
                  setShoppers(newData);
                });
              setLongitude(data1.results[0].lon);
              setLatitude(data1.results[0].lat);
              setInit(true);
              addRetNewClose();
              setAddShopperCheck(true);
              setAddShopperOpen(true);
            }
          });
      });
  };
  const addressChang = (e) => {
    setRetailer_address(e.target.value);
  };
  const newdistance = getDistance(
    { latitude: 51.5103, longitude: 7.49347 },
    { latitude: "51° 31' N", longitude: "7° 28' E" }
  );
  const handlesub = () => {
    if (shopper_id === undefined) {
      console.log("please select a shopper");
    } else {
      let order = {};
      order.products = prod;
      order.retailer_address = retailer_address;
      order.retailer_id = retailer_id;
      order.shopper_location = shopper_location;
      order.retailer_location = { latitude, longitude };
      order.shopper_id = shopper_id;
      console.log(order);
      setIni(false);
      fetch("https://api.allroundcare.org/order/create?type=admin", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(order),
      }).then((res) => {
        if (res.status !== 200) {
          console.log(res);
          console.log("not done");
        } else {
          setIni(true);
          closeAddShopper();
        }
      });
    }
  };

  useEffect(() => {
    fetch("https://api.allroundcare.org/utilites/unlimited_products")
      .then((res) => {
        if (res.status !== 200) {
          throw Error("Failed to fetch products");
        }
        return res.json();
      })
      .then((data) => {
        let mappedData = [];
        if (data.length > 0) {
          mappedData = data.map((eachElement) => {
            return {
              ...eachElement,
              isChecked: false,
              quantity: 0,
            };
          });
        }
        setProducts(mappedData);
      });

    fetch("https://api.allroundcare.org/users/retialers")
      .then((res) => {
        if (res.status !== 200) {
          //console.log("error")
          throw Error("Failed to load retailers");
        }
        return res.json();
      })
      .then((data) => {
        setRetailers(data.body);
      });
  }, []);
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: { xs: "100%", sx: "100%", md: "82.64%" },
        backgroundColor: "none",
        px: "25px",
        paddingTop: "32px",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          width: "55%",
          display: "flex",
          flexDirection: "row",
          cursor: "pointer",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          onClick={() => navigate("/orders")}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <ArrowBackIosIcon sx={{ color: "#28A745" }} />
          <Typography variant="p" sx={{ color: "#28A745" }}>
            back
          </Typography>
        </Box>
        <Typography variant="h5">Add Orders</Typography>
      </Box>
      <TopBarOrder
        handleCheckout={handleCheckout}
        handleChanger={handleChanger}
        search={search}
      />
      {addEmpty && (
        <Typography variant="p" sx={{ color: "red" }}>
          Cart cannot be empty. Please add products and quantity in order to
          successfully add a new order.
        </Typography>
      )}
      <Box
        sx={{
          width: "100%",
          paddingBottom: "32px",
          display: { xs: "flex", sm: "flex", md: "grid" },
          flexDirection: "column",
          gridTemplateColumns: "auto auto auto",
          columnGap: "32px",
          rowGap: "32px",
          marginTop: "32px",
        }}
      >
        {products
          .filter((product) => {
            return search.toLowerCase() === ""
              ? product
              : product.product_name.toLowerCase().includes(search);
          })
          .map((product, index) => (
            <Box
              key={product._id}
              sx={{
                display: "block",
                width: { xs: "100%", sm: "100%", md: "100%" },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  p: "8px",
                  backgroundColor: "#C7E7CE",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  key={product._id}
                  value={product._id}
                  checked={product.isChecked}
                  onChange={(e) => handleClick(e, product._id, product)}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    color: "#069426",
                    "&.Mui-checked": { color: "#069426" },
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    border: "1px solid #28A745",
                    padding: "3px",
                    alignItems: "center",
                  }}
                >
                  <IconButton onClick={() => handleSub(product._id, product)}>
                    <RemoveIcon />
                  </IconButton>
                  {product.quantity}
                  <IconButton onClick={() => handleAdd(product._id, product)}>
                    <AddIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={{ width: "100%", height: "300px", marginTop: "16px" }}>
                <img
                  src={product.product_images[0].src}
                  alt="product images"
                  style={{ width: "100%", height: "100%" }}
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  p: "0px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "16px",
                }}
              >
                <Typography variant="p" sx={{ fontWeight: "500" }}>
                  {product.product_name}
                </Typography>
                <Typography>{product.prices[0].amount}</Typography>
              </Box>
            </Box>
          ))}
      </Box>
      {checkOpen && (
        <CheckOutModal
          checkOutOpen={checkOutOpen}
          checkOutClose={checkOutClose}
          prod={prod}
          totalCosts={totalCosts}
          handleNext={handleNext}
        />
      )}
      {addRet && (
        <AddRetailer
          addRetOpen={addRetOpen}
          addRetClose={addRetClose}
          retailers={retailers}
          handleChangedd={handleChangedd}
          searched={searched}
          retailerd={retailerd}
          handleChangerd={handleChangerd}
          catchId={catchId}
          addressChange={addressChange}
          retailer_address={retailer_address}
          handleNex={handleNex}
          initial={initial}
          addNewRet={addNewRet}
          error={error}
        />
      )}
      {addNewRetailer && (
        <AddRetAdd
          openAdd={openAdd}
          addRetNewClose={addRetNewClose}
          init={init}
          subb={subb}
          retailer_address={retailer_address}
          addressChang={addressChang}
        />
      )}
      {addShopperCheck && (
        <AddShopper
          addShopperOpen={addShopperOpen}
          shoppers={shoppers}
          closeAddShopper={closeAddShopper}
          handleChanget={handleChanget}
          searcher={searcher}
          handleChangeed={handleChangeed}
          value={value}
          handleLet={handleLet}
          ini={ini}
          handlesub={handlesub}
        />
      )}
    </Box>
  );
};

export default AddOrderMain;
