import React from "react";
import { Modal, styled, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { ReactComponent as Forgot } from "../../Svg/forgot.svg";
import SendIcon from "@mui/icons-material/Send";

const StyledModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "auto",
});

const ForgotPasswordModal = ({
  forgotPasswordOpen,
  closeForget,
  email,
  handleEmail,
  submitEmail,
}) => {
  return (
    <StyledModal
      open={forgotPasswordOpen}
      onClose={closeForget}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          width: { xs: "90vw", sm: "90vw", md: "40vw" },
          px: "44px",
          py: "30px",
          backgroundColor: "white",
          borderRadius: "10px",
          display: "block",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <CloseIcon sx={{ cursor: "pointer" }} onClick={() => closeForget()} />
        </Box>
        <Box
          sx={{
            width: "100%",
            padding: "0px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Forgot style={{ width: "200px", height: "150px" }} />
          <Typography variant="h6">Forgot Password?</Typography>
          <Typography
            variant="p"
            sx={{ color: "rgba(0,0,0, 0.3)", textAlign: "center" }}
          >
            Don't worry, we got you! Please enter your email to retrieve your
            password
          </Typography>
        </Box>
        <Box
          component="form"
          onSubmit={(e) => submitEmail(e)}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            padding: "0px",
            marginTop: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "98%",
              padding: "0px",
              m: "0px",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontWeight: "500", color: "rgba(0,0,0,0.8)" }}
            >
              Email
            </Typography>
            <TextField
              required
              type="email"
              id="outlined-required"
              value={email}
              placeholder="Enter your email here"
              onChange={(e) => handleEmail(e)}
              sx={{ width: "100%", borderRadius: "8px", mt: "5px" }}
            />
          </Box>
          <IconButton
            type="submit"
            size="large"
            sx={{ color: "#28A745", marginTop: "24px" }}
          >
            <SendIcon />
          </IconButton>
        </Box>
      </Box>
    </StyledModal>
  );
};

export default ForgotPasswordModal;
