import { Box, OutlinedInput, IconButton,InputAdornment,  } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, {useState, useEffect, useCallback, useRef} from "react";

const SearchShopper = ({handleChangedd}) => {
  return (
    <Box component='div' sx={{ display: 'flex',padding: '0px', marginTop:'16px'}}>
            <OutlinedInput  sx={{backgroundColor: 'white', height: '55px', borderRadius: '10px', width:'100%'}}
            id="outlined-adornment-password"
            type="text"
            placeholder="Search..."
            onChange={(e)=>handleChangedd(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  //onClick={handleClickShowPassword}
                  //onMouseDown={handleMouseDownPassword}
                  edge="end" 
                >
                  <SearchIcon sx={{color:'#28A745'}} />
                </IconButton>
              </InputAdornment>
            }
          />
    </Box>
  )
}

export default SearchShopper
