import { Box } from "@mui/material";
import Navbar from "./Sub-Components/Navbar";
import Sidebar from "./Sub-Components/SideBar6";
import LogisticMain from "./Sub-Components/LogisticMain";

function Logistics() {
  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "15rem auto",
          padding: "0px",
          marginTop: "80px",
          gap: "1rem",
        }}
      >
        <Sidebar />
        <LogisticMain />
      </Box>
    </Box>
  );
}

export default Logistics;
