import React from 'react';
import { Box, Stack } from "@mui/material";
import Navbar from './Navbar';
import Sidebar3 from './Sidebar3';
import AddProductsMain from './AddProductsMain';


const AddProducts = () => {
  return (
    <Box>
        <Navbar />
        <Box sx={{ display: "flex", flexDirection: 'row', padding: '0px', marginTop: '80px', justifyContent: 'center' }}>
            <Sidebar3 />
            <AddProductsMain />
        </Box>
    </Box>
  )
}

export default AddProducts
