import React from 'react';
import { Box, Stack } from '@mui/material';
import Navbar from './Navbar';
import Sidebar2 from './Sidebar2';
import AddOrderMain from './AddOrderMain';

const AddOrders = () => {
  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '0px',
          marginTop: '80px',
          justifyContent: 'center',
        }}
      >
        <Sidebar2 />
        <AddOrderMain />
      </Box>
    </Box>
  );
};

export default AddOrders;
