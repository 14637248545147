import { Box, Typography, Button, Avatar, TextField } from '@mui/material';
import React, { useState, useCallback } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useDropzone } from 'react-dropzone';

const ProfileMain = () => {
  const [files, setFiles] = useState([]);
  const [last_name, setLast_name] = useState('');
  const [first_name, setFirst_name] = useState('');
  const navigate = useNavigate();

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    maxFiles: 1,
  });
  const firstNameChange = (e) => {
    setFirst_name(e.target.value);
  };
  const lastNameChange = (e) => {
    setLast_name(e.target.value);
  };
  const handleSub = (e) => {
    e.preventDefault();
    console.log({ email, phone, first_name, last_name, files });
  };

  const firstname = localStorage.getItem('firstname');
  const email = localStorage.getItem('email');
  const phone = localStorage.getItem('phone');
  const lastname = localStorage.getItem('lastname');

  return (
    <Box
      sx={{
        width: { xs: '100%', sx: '100%', md: '82.64%' },
        backgroundColor: 'none',
        px: '25px',
        paddingTop: '32px',
        paddingBottom: '32px',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          width: '55%',
          display: 'flex',
          flexDirection: 'row',
          cursor: 'pointer',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          onClick={() => navigate('/settings')}
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <ArrowBackIosIcon sx={{ color: '#28A745' }} />
          <Typography variant="p" sx={{ color: '#28A745' }}>
            back
          </Typography>
        </Box>
        <Typography variant="h5">Profile Information</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          padding: '0px',
          marginTop: '32px',
        }}
      >
        <Box
          onSubmit={(e) => handleSub(e)}
          component="form"
          sx={{
            width: { xs: '100%', sm: '100%', md: '55%' },
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            p: '0px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {files.map((file, index) => (
              <Box
                key={file.name}
                sx={{ width: '100px', height: '100px', borderRadius: '50%' }}
              >
                <Avatar
                  sx={{ width: '100%', height: '100%', borderRadius: '50%' }}
                  src={
                    file.preview
                      ? file.preview
                      : 'https://via.placeholder.com/24'
                  }
                  onLoad={() => {
                    URL.revokeObjectURL(file.preview);
                  }}
                />
              </Box>
            ))}
            <Box sx={{ padding: '0px', marginTop: '16px' }}>
              <div
                {...getRootProps()}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <input {...getInputProps()} />
                <CameraAltIcon sx={{ color: '#28A745' }} />
                <Typography variant="p" sx={{ color: 'rgba(0,0,0,0.5)' }}>
                  Upload profile picture
                </Typography>
              </div>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: 'block', sm: 'grid', md: 'grid' },
              gridTemplateColumns: 'auto auto',
              rowGap: '20px',
              columnGap: '16px',
              marginTop: '16px',
            }}
          >
            <Box sx={{ display: 'block' }}>
              <Typography sx={{ color: '#696F79' }}>First Name</Typography>
              <TextField
                type="text"
                id="outlined-basic"
                value={firstname}
                onChange={(e) => firstNameChange(e)}
                variant="outlined"
                placeholder={
                  firstname !== null ? firstname : 'Enter first name'
                }
                sx={{
                  border: '1px solid #A1A1A1',
                  borderRadius: '8px',
                  width: '100%',
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'block',
                marginTop: { xs: '16px', sm: '0px', md: '0px' },
              }}
            >
              <Typography sx={{ color: '#696F79' }}>Last Name</Typography>
              <TextField
                type="text"
                id="outlined-basic"
                value={lastname}
                onChange={(e) => lastNameChange(e)}
                variant="outlined"
                placeholder={lastname === 0 ? 'Enter last name' : lastname}
                sx={{
                  border: '1px solid #A1A1A1',
                  borderRadius: '8px',
                  width: '100%',
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'block',
                marginTop: { xs: '16px', sm: '0px', md: '0px' },
              }}
            >
              <Typography sx={{ color: '#696F79' }}>Email</Typography>
              <TextField
                type="text"
                id="outlined-basic"
                value={email}
                // onChange={(e)=>productChange(e)}
                // {...register("product_name")}
                variant="outlined"
                placeholder={email === null ? 'Enter your email' : email}
                sx={{
                  border: '1px solid #A1A1A1',
                  borderRadius: '8px',
                  width: '100%',
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'block',
                marginTop: { xs: '16px', sm: '0px', md: '0px' },
              }}
            >
              <Typography sx={{ color: '#696F79' }}>Phone Number</Typography>
              <TextField
                type="text"
                id="outlined-basic"
                value={phone}
                // onChange={(e)=>productChange(e)}
                //{...register("product_name")}
                variant="outlined"
                placeholder={phone === null ? 'Enter your phone number' : phone}
                sx={{
                  border: '1px solid #A1A1A1',
                  borderRadius: '8px',
                  width: '100%',
                }}
              />
            </Box>
          </Box>
          <Button
            type="submit"
            variant="contained"
            sx={{
              width: '100%',
              marginTop: '24px',
              borderRadius: '10px',
              height: '48px',
              backgroundColor: '#28A745',
              '&:hover': { backgroundColor: '#44ab5c' },
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileMain;
