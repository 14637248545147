import React, {useState, useEffect, useCallback, useRef} from "react";
import {Modal, styled, Box, Typography, modalClasses, Stack, Button} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';



const StyledModal = styled(Modal)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    //paddingTop:"150px",
    //paddingBottom:"60px",
    overflow:"auto"
});

const CreateCategoryModal = ({createCategoryOpen,closeCategory, error, handleSubmit, categoryName, category, handleChangedd }) => {

    
    return ( 
        <StyledModal 
        open={createCategoryOpen}
        onClose={closeCategory}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
           <Box sx={{width:{xs: "90vw", sm: "90vw", md:"40vw"},px:{xs:'20px', sm:'20px', md: '44px'}, py:'30px', backgroundColor:'white', borderRadius:'10px', display:'block'}}>
              <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                <Typography variant='h5' sx={{fontWeight:'bold'}}>Add Category</Typography>
                <CloseIcon sx={{cursor:'pointer'}} onClick={()=>closeCategory()}/>
              </Box>
              <Box sx={{py:{xs:'20px', sm:'20px', md:'26px'}, px:{xs:'20px', sm:'20px', md:'32px'}, border:'1px solid #B1B1B1', borderRadius:'10px', mt:'24px', display:'block'}}>
                <Box sx={{m:'0px', p:'0px', display:'flex', flexDirection:'column'}}>
                    <Typography variant="p" sx={{fontSize:'16px', color:'#696F79', fontWeight:'500'}}>Category Name</Typography>
                    <TextField
                    required
                    id="outlined-required"
                    value={categoryName}
                    placeholder='Enter category name'
                    onChange={(e)=>handleChangedd(e)}
                    sx={{width:'100%', borderRadius:'8px', height:'44px',mt:'5px'}}
                    />
                    {error && <Typography variant="p" sx={{mt:'10px', color:'red', fontSize:'14px'}}>*Field is required</Typography>}
                </Box>
                <Button 
                variant="contained"
                onClick={()=>handleSubmit()}
                sx={{width:'100%',height:'44px', backgroundColor:'#28A745', color:'white', mt:'24px', borderRadius:'8px', alignItems:'center', '&:hover': {backgroundColor:'#44ab5c'}}}>
                    Add Category
                </Button>
              </Box>
           </Box>
        </StyledModal>
    );
}
 
export default CreateCategoryModal;