import React, {useState, useEffect, useCallback, useRef} from "react";
import { Box,Button, Modal } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate, useLocation } from "react-router-dom";
import SearchBar from "./SearchBar";
import CreateCategoryModal from "./CreateCategoryModal";


const TopBar = ({search, handleChange, error, handleSubmit, categoryName, handleChangedd, createCategoryOpen, openCat, openCategory, closeCategory}) => {

    
    const navigate = useNavigate()

    return ( 
        <Box component='div' sx={{backgroundColor: '#C7E7CE', borderRadius:'10px', px:'16px', py:'25px', display: { sm: "block", md:"flex"}, flexDirection: 'row', justifyContent: {sm: 'center', md:'space-between'}, alignItems:'center'}}>
            <SearchBar search={search} handleChange={handleChange}/>
            <Box sx={{display:'flex', flexDirection: {xs:'column', sm:'column', md:'row'}}}>
                <Button variant="outlined"  sx={{width: { xs:'100%',sm: "100%", md: "177px"}, marginTop:{xs:'8px', sm:'8px', md:'0px'}, height: '50px', border:'1px solid #28A745', color:'#28A745', borderRadius: '10px', '&:hover': {backgroundColor:'#28A745', color:'white', border:'1px solid transparent'}}} onClick={()=>openCategory()}>
                  Add Category
               </Button>
               <Button variant="contained" startIcon={<AddCircleIcon />} sx={{width: { xs:'100%',sm: "100%", md: "177px"}, marginTop:{xs:'8px', sm:'8px', md:'0px'}, marginLeft:{xs:'0px', sm:'0px', md:'8px' }, height: '50px', backgroundColor: '#28A745', borderRadius: '10px', '&:hover': {backgroundColor:'#44ab5c'}}} onClick={()=>navigate("/Addproducts")}>
                  Add Products
               </Button>
            </Box>
            {openCat && <CreateCategoryModal createCategoryOpen={createCategoryOpen} closeCategory={closeCategory} error={error} handleSubmit={handleSubmit} categoryName={categoryName} handleChangedd={handleChangedd}/>}
        </Box>
     );
}
 
export default TopBar;