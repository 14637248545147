import React, { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import axios from "axios";
import "../../index.css";
import ExpressOrder from "./ExpressOrder";
import RideShareOrder from "./RideShareOrder";

function LogisticMain() {
  const token = localStorage.getItem("token");
  const [packages, setPackages] = useState([]);
  const [change, setChange] = useState("");
  const [expressOrder, setExpressOrder] = useState(true);
  const [rideShareOrder, setRideShareOrder] = useState(false);

  useEffect(() => {
    const getAllPackages = async () => {
      try {
        const response = await axios.get(
          "https://api.allroundcare.org/admin/packages/all-packages",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status !== 200) {
          throw new Error("Unable to fetch data from resources");
        } else if (response.status === 200) {
          setPackages(response.data.data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    getAllPackages();
  }, []);

  let expressPackages = packages.filter((pkg) =>
    pkg.delivery_type.includes("express")
  );

  let rideSharePackages = packages.filter((pkg) =>
    pkg.delivery_type.includes("ride_share")
  );

  const handleChange = (e) => {
    setChange(e.target.value);
  };

  const handleExpressOrder = () => {
    setExpressOrder(true);
    setRideShareOrder(false);
  };
  const handleRideShareOrder = () => {
    setRideShareOrder(true);
    setExpressOrder(false);
  };

  return (
    <Box
      component="div"
      sx={{
        paddingBottom: "24px",
        marginTop: "12px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box
        components="div"
        sx={{
          padding: "16px",
          margin: "0px",
          backgroundColor: "#C7E7CE",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <FormControl>
          <InputLabel id="demo-simple-select-label">Order Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={change}
            label="Category"
            sx={{
              width: "200px",
              height: "50px",
              backgroundColor: "white",
              borderRadius: "10px",
            }}
            onChange={(e) => handleChange(e)}
          >
            <MenuItem
              onClick={() => handleExpressOrder()}
              value={"Express Order"}
              selected
            >
              Express Order
            </MenuItem>
            <MenuItem
              onClick={() => handleRideShareOrder()}
              value={"Ride Share Order"}
              selected
            >
              Ride Share Order
            </MenuItem>
          </Select>
        </FormControl>
      </Box>

      {expressOrder && <ExpressOrder expressPackages={expressPackages} />}
      {rideShareOrder && (
        <RideShareOrder rideSharePackages={rideSharePackages} />
      )}
    </Box>
  );
}

export default LogisticMain;
