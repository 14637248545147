import React, { useState, useEffect } from "react";
import { Box, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import TopBar2 from "./TopBar2";
import { useNavigate } from "react-router-dom";
import ShopperDecline from "./ShopperDecline";
import Dispatched from "./Dispatched";
import UnassignedOrder from "./UnassignedOrder";
import AwaitingOrder from "./AwaitingOrder";
import Delivered from "./Delivered";
import AssignedOrder from "./AssignedOrder";
import AcceptedShopper from "./AcceptedShopper";
import ReceiptSubmitted from "./ReceiptSubmtted";
import PaymentApproved from "./PaymentApproved";

const OrderMain = ({ allorders, createdOrders: unassignedOrders }) => {
  const [change, setChange] = useState("");
  const [unassigned, setUnassigned] = useState(true);
  const [assigned, setAssigned] = useState(false);
  const [receipt, setReceipt] = useState(false);
  const [awaiting, setAwaiting] = useState(false);
  const [dispatched, setDispatched] = useState(false);
  const [acceptShopper, setAcceptShopper] = useState(false);
  const [payment, setPayment] = useState(false);
  const [delivered, setDelivered] = useState(false);
  const [declines, setDeclines] = useState(false);
  const [shoppers, setShoppers] = useState([]);
  const [retailers, setRetailers] = useState([]);

  let assignedOrders = allorders.filter((order) =>
    order.current_status.includes("assigned to shopper")
  );

  let acceptedShopper = allorders.filter((order) =>
    order.current_status.includes("accepted by shopper")
  );

  let awaitingDispatch = allorders.filter((order) =>
    order.current_status.includes("ready for delivery")
  );

  let declinedOrder = allorders.filter((order) =>
    order.current_status.includes("declined by shopper")
  );

  let dispatch = allorders.filter((order) =>
    order.current_status.includes("accepted by dispatch rider")
  );

  let deliveredDispatched = allorders.filter((order) =>
    order.current_status.includes("completed")
  );

  let receiptSubmited = allorders.filter((order) =>
    order.current_status.includes("reciept submitted")
  );

  let approvedByAdmin = allorders.filter((order) =>
    order.current_status.includes("payment approved by admin")
  );

  const handleChange = (e) => {
    setChange(e.target.value);
  };

  const handleAssign = () => {
    setAssigned(true);
    setAwaiting(false);
    setDispatched(false);
    setDelivered(false);
    setDeclines(false);
    setUnassigned(false);
    setAcceptShopper(false);
    setReceipt(false);
    setPayment(false);
  };

  const handleAwaiting = () => {
    setAssigned(false);
    setAwaiting(true);
    setDispatched(false);
    setDelivered(false);
    setDeclines(false);
    setUnassigned(false);
    setAcceptShopper(false);
    setReceipt(false);
    setPayment(false);
  };
  const handleDispatched = () => {
    setAwaiting(false);
    setAssigned(false);
    setDispatched(true);
    setDelivered(false);
    setDeclines(false);
    setUnassigned(false);
    setReceipt(false);
    setAcceptShopper(false);
    setPayment(false);
  };
  const handleDelivered = () => {
    setAwaiting(false);
    setDispatched(false);
    setDelivered(true);
    setDeclines(false);
    setUnassigned(false);
    setAssigned(false);
    setReceipt(false);
    setPayment(false);
    setAcceptShopper(false);
  };
  const handleDeclines = () => {
    setAwaiting(false);
    setDispatched(false);
    setDelivered(false);
    setDeclines(true);
    setUnassigned(false);
    setAssigned(false);
    setReceipt(false);
    setAcceptShopper(false);
    setPayment(false);
  };

  const handleUnassigned = () => {
    setAwaiting(false);
    setDispatched(false);
    setDelivered(false);
    setDeclines(false);
    setUnassigned(true);
    setAssigned(false);
    setReceipt(false);
    setAcceptShopper(false);
    setPayment(false);
  };

  const handleShopperAccepted = () => {
    setAwaiting(false);
    setDispatched(false);
    setDelivered(false);
    setDeclines(false);
    setUnassigned(false);
    setAssigned(false);
    setReceipt(false);
    setAcceptShopper(true);
    setPayment(false);
  };

  const handleReciptSubmited = () => {
    setReceipt(true);
    setAwaiting(false);
    setDispatched(false);
    setDelivered(false);
    setDeclines(false);
    setUnassigned(false);
    setAssigned(false);
    setAcceptShopper(false);
    setPayment(false);
  };

  const handlePayment = () => {
    setPayment(true);
    setReceipt(false);
    setAwaiting(false);
    setDispatched(false);
    setDelivered(false);
    setDeclines(false);
    setUnassigned(false);
    setAssigned(false);
    setAcceptShopper(false);
  };

  const navigate = useNavigate();
  const handleAddOrder = () => {
    navigate("/addorder");
  };

  useEffect(() => {
    fetch("https://api.allroundcare.org/users/shoppers")
      .then((res) => {
        if (res.status !== 200) {
          console.log("error");
        }
        return res.json();
      })
      .then((data) => {
        setShoppers(data.body);
      })
      .catch((err) => {
        console.log(err);
      });

    fetch("https://api.allroundcare.org/users/retialers")
      .then((res) => {
        if (res.status !== 200) {
          throw Error("unable to fetch data from resource");
        }
        return res.json();
      })
      .then((data) => {
        setRetailers(data.body);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        px: "25px",
        paddingTop: " 10px",
      }}
    >
      <TopBar2 handleAddOrder={handleAddOrder} />
      <Box
        component="div"
        sx={{
          paddingBottom: "24px",
          marginTop: "12px",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box
          components="div"
          sx={{
            padding: "16px",
            margin: "0px",
            backgroundColor: "#C7E7CE",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <FormControl>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={change}
              label="Category"
              sx={{
                width: "200px",
                height: "50px",
                backgroundColor: "white",
                borderRadius: "10px",
              }}
              onChange={(e) => handleChange(e)}
            >
              <MenuItem
                onClick={() => handleUnassigned()}
                value={"Unassigned Order"}
                selected
              >
                Unassigned Order
              </MenuItem>
              <MenuItem
                onClick={() => handleAwaiting()}
                value={"Awaiting Dispatch"}
              >
                Awaiting Dispatch
              </MenuItem>
              <MenuItem onClick={() => handleAssign()} value={"assigned order"}>
                Assigned Order
              </MenuItem>
              <MenuItem
                onClick={() => handleShopperAccepted()}
                value={"accepted by shopper"}
              >
                Accepted By Shopper
              </MenuItem>
              <MenuItem onClick={() => handleDispatched()} value={"Dispatched"}>
                Accepted By Dispatcher
              </MenuItem>
              <MenuItem onClick={() => handleDelivered()} value={"Delivered"}>
                Delivered
              </MenuItem>
              <MenuItem
                onClick={() => handleReciptSubmited()}
                value={"Receipt Submitted"}
              >
                Receipt Submitted
              </MenuItem>
              <MenuItem
                onClick={() => handleDeclines()}
                value={"Shopper Declines"}
              >
                Shopper Declines
              </MenuItem>
              <MenuItem
                onClick={() => handlePayment()}
                value={"Payment Approved"}
              >
                Payment Approved
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        {unassigned && (
          <UnassignedOrder
            retailers={retailers}
            unassignedOrders={unassignedOrders}
          />
        )}
        {receipt && (
          <ReceiptSubmitted
            retailers={retailers}
            receiptSubmited={receiptSubmited}
          />
        )}
        {awaiting && <AwaitingOrder awaitingDispatch={awaitingDispatch} />}
        {assigned && <AssignedOrder assignedOrders={assignedOrders} />}
        {dispatched && <Dispatched dispatch={dispatch} />}
        {delivered && <Delivered deliveredDispatched={deliveredDispatched} />}
        {acceptShopper && <AcceptedShopper acceptedShopper={acceptedShopper} />}
        {payment && <PaymentApproved approvedByAdmin={approvedByAdmin} />}
        {declines && (
          <ShopperDecline
            declinedOrder={declinedOrder}
            shoppers={shoppers}
            retailers={retailers}
          />
        )}
      </Box>
    </Box>
  );
};

export default OrderMain;
